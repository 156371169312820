import { Flex, Heading, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import PollsList from './PollsList';
import { projectQuery } from './ProjectsContainer';

const ProjectBrandingSettings = React.lazy(() => import('./ProjectBrandingSettings'));

export const ProjectPage: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get('tab');
  const { projectId } = useParams<{ projectId: string }>();
  const { data: project, isLoading } = useQuery(projectQuery(projectId!));

  return (
    <Flex direction="column" py={6} px={2}>
      {isLoading ? <Heading>loading...</Heading> : null}
      <Heading my={3}>{project?.name}</Heading>

      <Tabs
        defaultIndex={tab == null || tab === 'polls' ? 0 : 1}
        colorScheme="purple"
        onChange={(index) => {
          const search = index === 0 ? '?tab=polls' : '?tab=branding';
          navigate({ search });
        }}
        isLazy
      >
        <TabList>
          <Tab>Video Polls</Tab>
          <Tab>Branding</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <PollsList />
          </TabPanel>
          <TabPanel>
            <ProjectBrandingSettings />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
};
