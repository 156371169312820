import { Box, Flex, Grid, GridItem, Heading } from '@chakra-ui/react';
import { QueryClient } from '@tanstack/react-query';
import React from 'react';
import { LoaderFunctionArgs, Route, Routes } from 'react-router-dom';
import { fetchProject, fetchProjects } from '../projects.service';
import { ProjectPage } from './ProjectPage';
import Sidebar from './Sidebar';
import { ReactComponent as EmptyElement } from '../assets/survey.svg';

export const projectsQuery = () => ({
  queryKey: ['projects'],
  queryFn: async () => {
    try {
      const projects = await fetchProjects();
      return projects.data;
    } catch (error) {
      throw new Error('Not Found');
    }
  },
});

export const projectQuery = (id: string) => ({
  queryKey: ['project', id],
  queryFn: async () => {
    try {
      const projects = await fetchProject(id);
      return projects.data;
    } catch (error) {
      throw new Error('Not Found');
    }
  },
});

export const projectsLoader = (queryClient: QueryClient) => async () => {
  const query = projectsQuery();
  try {
    return queryClient.getQueryData(query as any) ?? (await queryClient.fetchQuery(query));
  } catch (error) {
    return null;
  }
};

export const projectLoader =
  (queryClient: QueryClient) =>
  async ({ params }: LoaderFunctionArgs) => {
    const query = projectQuery(params.projectId!);
    try {
      return queryClient.getQueryData(query as any) ?? (await queryClient.fetchQuery(query));
    } catch (error) {
      return null;
    }
  };

const ProjectsContainer: React.FC = () => {
  return (
    <Grid h="100%" w="100%" templateRows="auto" templateColumns="20% 1fr" gap={4} overflowY="auto">
      <GridItem>
        <Sidebar />
      </GridItem>
      <GridItem overflowY="auto">
        <Routes>
          <Route
            index
            element={
              <Flex width="100%" height="100%" alignItems="center" justifyContent="space-around" direction="column">
                <Heading as="h1">Select Project</Heading>
                <Box width="640px">
                  <EmptyElement />
                </Box>
              </Flex>
            }
          />
          <Route path=":projectId" element={<ProjectPage />} loader={projectLoader as keyof typeof projectLoader} />
        </Routes>
      </GridItem>
    </Grid>
  );
};

export default ProjectsContainer;
