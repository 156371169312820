import React from 'react';
import { GridItem, Flex, Text } from '@chakra-ui/react';
import { ResultsChart } from './ResultsChart';

export const ResultsView: React.FC = () => {
  return (
    <GridItem p={5} colSpan={2} placeItems="center">
      <Flex mt={15} direction="column" alignItems="center" justifyContent="center">
        <Flex direction="column" w="800px" p={6}>
          <Text fontSize="2xl" fontWeight="bold">
            Results
          </Text>
        </Flex>
        <ResultsChart />
      </Flex>
    </GridItem>
  );
};
