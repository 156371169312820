import axios, { AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import debounce from 'lodash-es/debounce';
import { debugLog } from './debug.log';
import { authService, StorageKeys } from './auth.service';

const { REACT_APP_API_ENDPOINT } = process.env;

const logout = async () => {
  try {
    await authService.logout();
    return redirect();
  } catch (error: any) {
    debugLog({ error });
    return redirect();
  }
};

const debounceUnauthorized = debounce(() => {
  console.error('Unauthorized');
  logout();
}, 1234);

const debounceForbidden = debounce(() => {
  console.error('Forbidden');
  logout();
}, 1234);

export enum StatusCode {
  Unauthorized = 401,
  Forbidden = 403,
  RequestEntityTooLarge = 413,
  TooManyRequests = 429,
  InternalServerError = 500,
}

const headers: Readonly<Record<string, string>> = {
  Accept: 'application/json',
  'Content-Type': 'application/json; charset=utf-8',
  'X-Requested-With': 'XMLHttpRequest',
};

const injectToken = (config: InternalAxiosRequestConfig) => {
  try {
    const token = localStorage.getItem(StorageKeys.AccessToken);
    if (token != null) {
      config.headers.setAuthorization(`Bearer ${token}`);
    }
    return config;
  } catch (error: any) {
    throw new Error(error);
  }
};

const handleError = (errorResponse: any) => {
  const { status = 500 } = errorResponse ?? { status: 500 };

  switch (status) {
    case StatusCode.InternalServerError: {
      break;
    }
    case StatusCode.RequestEntityTooLarge: {
      break;
    }
    case StatusCode.Forbidden: {
      debounceForbidden();
      break;
    }
    case StatusCode.Unauthorized: {
      debounceUnauthorized();
      break;
    }
  }

  return Promise.reject(errorResponse);
};

const httpRequest = axios.create({ baseURL: `${REACT_APP_API_ENDPOINT}`, headers });

httpRequest.interceptors.request.use(injectToken, (error) => Promise.reject(error));
httpRequest.interceptors.response.use(
  (response) => response,
  (error) => {
    const { response } = error;

    if (axios.isCancel(error)) {
      // do nothing when request is canceled due to abort controller
      return Promise.resolve({ data: null });
    }

    return handleError(response).then((error) => Promise.reject(error));
  }
);

const request = async <T = unknown, R = AxiosResponse<T>>(config: AxiosRequestConfig): Promise<R> => {
  return await httpRequest.request({
    ...config,
    headers: { ...headers, ...config?.headers } as AxiosRequestConfig['headers'],
  });
};

const get = async <T = unknown, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> => {
  return await httpRequest.get<T, R>(url, {
    ...config,
    headers: { ...headers, ...config?.headers } as AxiosRequestConfig['headers'],
  });
};

const post = async <T = unknown, R = AxiosResponse<T>>(
  url: string,
  data?: T,
  config?: AxiosRequestConfig
): Promise<R> => {
  return await httpRequest.post<T, R>(url, data, {
    ...config,
    headers: { ...headers, ...config?.headers } as AxiosRequestConfig['headers'],
  });
};

const put = async <T = unknown, R = AxiosResponse<T>>(
  url: string,
  data?: T,
  config?: AxiosRequestConfig
): Promise<R> => {
  return await httpRequest.put<T, R>(url, data, {
    ...config,
    headers: { ...headers, ...config?.headers } as AxiosRequestConfig['headers'],
  });
};

const del = async <T = unknown, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> => {
  return await httpRequest.delete<T, R>(url, {
    ...config,
    headers: { ...headers, ...config?.headers } as AxiosRequestConfig['headers'],
  });
};

const redirect = () => {
  localStorage.clear();
  window.location.replace('/login');
};

export const http = {
  request,
  get,
  post,
  put,
  delete: del,
};
