import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { Bar, BarChart, CartesianGrid, LabelList, Tooltip, XAxis, YAxis } from 'recharts';
import { Box, Flex, Skeleton, Stack, Text } from '@chakra-ui/react';
import { fetchPollResults } from '../viewer.service';

const COLORS = [
  '#82ca9d',
  '#d53f8c',
  ' #805ad5',
  '#3182ce',
  '#38b2ac',
  '#0088FE',
  '#00C49F',
  '#FFBB28',
  '#FF8042',
  '#0C4A6E',
];

const QuestionCard: React.FC<any> = ({ question, answers, index }) => {
  return (
    <Flex direction="column" border="1px solid" borderColor="gray.200" p={6} maxW="800px">
      <Text fontWeight="bold">Question {index}</Text>
      <Text fontSize="lg">{question?.text}</Text>
      <Box mb={4} />
      <BarChart
        width={700}
        height={360}
        data={answers}
        layout="vertical"
        margin={{ top: 5, right: 20, left: -40, bottom: 5 }}
      >
        <XAxis type="number" />
        <YAxis type="category" dataKey="text" axisLine={false} tickLine={false} tick={false}></YAxis>
        <CartesianGrid strokeDasharray="2 2" />
        <Tooltip />
        <Bar dataKey="votes" fill={COLORS[index % COLORS.length]} height={20}>
          <LabelList dataKey="text" position="insideTopLeft" style={{ fill: 'white' }} />
        </Bar>
      </BarChart>
    </Flex>
  );
};
export const ResultsChart: React.FC = () => {
  const { pollId } = useParams<{ pollId: string }>();
  const { isLoading, data: results } = useQuery(['poll', pollId], () => fetchPollResults(pollId!));

  return isLoading ? (
    <Stack spacing={6}>
      <Skeleton height="360px" width="700px" />
      <Skeleton height="360px" width="700px" />
      <Skeleton height="360px" width="700px" />
      <Skeleton height="360px" width="700px" />
    </Stack>
  ) : (
    <Stack spacing={6}>
      {results?.map(({ question, answers }: any, i: number) => {
        return <QuestionCard key={i} index={i + 1} question={question} answers={answers} />;
      })}
    </Stack>
  );
};
