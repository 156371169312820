import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Image,
  Text,
} from '@chakra-ui/react';
import { Branding, useStateMachine } from 'little-state-machine';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router';
import { FileUploader } from '../FileUploader/components/FileUploader';
import { fetchCurrentUser, updateUser } from '../Profile/profile.service';
import { createProject, createProjectPoll, updateProjectBranding } from '../Projects/projects.service';
import { Project } from '../types';
import { ColorPickerPopover } from './ColorPickerPopover';
import updateOnboarding, { completeOnboarding, resetOnboarding } from './updateOnboarding';
import { OnboardingVideo } from './components/Video';
import { projectsQuery } from '../Projects/components/ProjectsContainer';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';

const schema = z.object({
  branding: z
    .object({
      name: z.string().min(2, 'Full name must be at least 2 characters'),
      companyName: z.string().min(2, 'Company name must be at least 2 characters'),
      role: z.string(),
    })
    .required(),
});

interface FormValues {
  branding: Branding;
}
export const Step3: React.FC = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const {
    register,
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({ resolver: zodResolver(schema) });
  const branding = watch('branding');

  const [isOpenColorPicker, setIsOpenColorPicker] = React.useState<boolean>(false);
  const { actions, state } = useStateMachine({ updateOnboarding, resetOnboarding });
  const { mutateAsync } = useMutation(createProjectPoll);
  const [defaultPollId, setDefaultPollId] = useState<string | null>(null);

  const createDefaultProject = async () => {
    const newProject = await createProjectAsync({ name: 'Default Project' });
    setProject(newProject);

    // create a default poll for the project
    const defaultPoll = await mutateAsync({
      projectId: newProject.id,
      name: 'Default Poll',
    });

    setDefaultPollId(defaultPoll.id);
  };

  const { data } = useQuery({
    ...projectsQuery(),
    onSuccess: (data) => {
      if (data && data?.length === 0 && project == null) {
        createDefaultProject();
      } else {
        if (data && data?.length > 0) {
          setProject(data?.[0]);
        }
      }
    },
  });

  const [project, setProject] = useState<Partial<Project> | null>(data && data?.length > 0 ? data?.[0] : null);

  const { mutateAsync: createProjectAsync } = useMutation(createProject);
  const { mutateAsync: updateUserAsync } = useMutation(updateUser, {
    onSuccess: () => {
      queryClient.invalidateQueries(['profile']);
    },
  });

  const { mutateAsync: updateBrandingAsync } = useMutation(updateProjectBranding, {
    onSuccess: () => {
      queryClient.invalidateQueries(['branding']);
    },
  });

  const { data: user } = useQuery(['profile'], () => fetchCurrentUser());

  const { onboarding } = state;

  const onSubmit = async (data: FormValues) => {
    actions.updateOnboarding(data);
    if (!user || !state.onboarding?.branding || !project?.id) {
      return;
    }

    await completeOnboarding(user.id, project.id, state.onboarding);
    actions.resetOnboarding();
    navigate(`/polls/${defaultPollId}/videos`);
  };

  const onBack = () => navigate('/onboarding/step-2');

  const handleLogoUpdated = async (logoUrl: string) => {
    if (project?.id == null) {
      return;
    }

    const updatedBranding = await updateBrandingAsync({
      projectId: project.id,
      branding: { logoUrl, colorCode: onboarding.branding?.brandingColor || '', projectId: project.id },
    });

    setProject({ ...project, branding: updatedBranding });
  };

  const handleProfilePictureUploaded = async (profilePictureUrl: string) => {
    if (!user) {
      return;
    }

    await updateUserAsync({ ...user, profilePictureUrl });
  };

  return (
    <Grid width="100%" height="100%" templateColumns="20% 1fr 1fr 20%" placeItems="center">
      <GridItem />
      <GridItem>
        <OnboardingVideo url="https://video-poll.fra1.digitaloceanspaces.com/video-poll-assets%2F3.mp4">
          <Box position="absolute" bottom={20} left={10}>
            <Flex
              mt="10px"
              border="1px"
              borderColor="gray.100"
              backgroundColor="gray.50"
              boxSize="43px"
              borderRadius="43px"
              alignItems="center"
              justifyContent="center"
            >
              <Box cursor="grab">
                {user && user?.profilePictureUrl ? (
                  <Image
                    alt="logo"
                    src={user?.profilePictureUrl || ''}
                    boxSize="43px"
                    objectFit="cover"
                    borderRadius="43px"
                  />
                ) : null}
              </Box>
            </Flex>
            <Text color="white" fontSize={12} fontWeight={700}>
              {branding?.name || user?.fullName}
            </Text>
            <Text color="white" fontSize={12} fontWeight={400}>
              {branding?.role}
            </Text>
            <Text color="white" fontSize={12} fontWeight={400}>
              {branding?.companyName}
            </Text>
          </Box>
        </OnboardingVideo>
      </GridItem>
      <GridItem width="100%">
        <div>
          <Grid templateColumns="repeat(2, 1fr)" width="100%" gap={4}>
            <GridItem>
              <FormControl isInvalid={errors.branding?.name != null}>
                <FormLabel htmlFor="name">Full Name</FormLabel>
                <Input
                  id="name"
                  type="text"
                  defaultValue={onboarding.branding?.name || user?.fullName}
                  {...register('branding.name')}
                />
                <FormErrorMessage>{errors.branding?.name && errors.branding?.name.message}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl isInvalid={errors.branding?.role != null}>
                <FormLabel htmlFor="role">Role</FormLabel>
                <Input id="role" type="text" defaultValue={onboarding.branding?.role} {...register('branding.role')} />
                <FormErrorMessage>{errors.branding?.role && errors.branding?.role.message}</FormErrorMessage>
              </FormControl>
            </GridItem>

            <GridItem>
              <FormControl isInvalid={errors.branding?.companyName != null}>
                <FormLabel htmlFor="companyName">Company Name</FormLabel>
                <Input
                  id="companyName"
                  type="text"
                  defaultValue={onboarding.branding?.companyName}
                  {...register('branding.companyName')}
                />
                <FormErrorMessage>
                  {errors.branding?.companyName && errors.branding?.companyName.message}
                </FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel htmlFor="brandingColor">Branding Color</FormLabel>
                <Flex>
                  <ColorPickerPopover
                    isOpen={isOpenColorPicker}
                    onOpen={() => setIsOpenColorPicker(true)}
                    onClose={() => setIsOpenColorPicker(false)}
                    defaultColor={onboarding.branding?.brandingColor}
                    onColorUpdate={(color) => {
                      setValue('branding.brandingColor', color);
                    }}
                  />
                  <Input
                    id="brandingColor"
                    type="text"
                    defaultValue={onboarding.branding?.brandingColor}
                    onFocus={() => setIsOpenColorPicker(true)}
                    {...register('branding.brandingColor')}
                  />
                </Flex>
              </FormControl>
            </GridItem>

            <GridItem>
              <FormControl>
                <FormLabel htmlFor="profilePicture">Profile Picture</FormLabel>
                <FileUploader
                  imageUrl={user?.profilePictureUrl}
                  uploadEndpoint={`${project?.id}/picture/${user?.id}`}
                  onUpload={handleProfilePictureUploaded}
                  hasCircularArea={true}
                />
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel htmlFor="companyLogo">Company Logo</FormLabel>
                <FileUploader
                  imageUrl={project?.branding?.logoUrl}
                  uploadEndpoint={`${project?.id}/logo`}
                  onUpload={handleLogoUpdated}
                />
              </FormControl>
            </GridItem>

            <GridItem colSpan={2}>
              <Flex width="100%" justifyContent="flex-end">
                <Button colorScheme="purple" variant="outline" onClick={onBack} type="button">
                  Back
                </Button>
                <Button colorScheme="purple" ml={4} onClick={handleSubmit(onSubmit)}>
                  Create your first poll
                </Button>
              </Flex>
            </GridItem>
          </Grid>
        </div>
      </GridItem>
      <GridItem />
    </Grid>
  );
};
