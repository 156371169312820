import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  Heading,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Stack,
  Text,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GoogleLogin } from '@react-oauth/google';
import React, { useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { isUserOnboarded } from '../Onboarding/updateOnboarding';
import { authService } from './api';
import { Authentication } from './Authentication';
import { useAuth } from './ProvideAuth';

export const LoginPage: React.FC = () => {
  const auth = useAuth();
  const [isAuthenticated, setIsAuthenticated] = useState(auth.user ?? false);
  const [isOnboarded, setIsOnboarded] = useState(false);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const login = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const data = await authService.login({ email, password });
      setIsLoading(false);
      if (data.user?.id) {
        auth.setUser(data.user);
        const isOnboarded = await isUserOnboarded(data.user?.id);
        setIsOnboarded(isOnboarded);
        setIsAuthenticated(true);
      }
    } catch (error: any) {
      setError(error?.response?.data?.message);
      setIsLoading(false);
      setIsAuthenticated(false);
    }
  };

  const togglePasswordVisibility = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setShowPass(!showPass);
  };

  if (isAuthenticated) {
    const goTo = isOnboarded ? '/projects' : '/onboarding';
    return <Navigate to={goTo} />;
  }

  return (
    <Authentication>
      <Heading as="h2" color="gray.600" fontSize="lg">
        Sign in
      </Heading>
      {error ? (
        <Text color="red.400" align="center">
          {error}
        </Text>
      ) : null}
      <form onSubmit={login}>
        <Stack spacing={4}>
          <Flex direction="column" gap={4} mb={6}>
            <FormControl isRequired>
              <InputGroup>
                <InputLeftElement>
                  <FontAwesomeIcon icon={['fal', 'envelope']} />
                </InputLeftElement>
                <Input
                  id="email"
                  placeholder="Email Address"
                  focusBorderColor="purple.500"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </InputGroup>
            </FormControl>
            <FormControl isRequired>
              <InputGroup>
                <InputLeftElement>
                  <FontAwesomeIcon icon={['fal', 'lock-alt']} />
                </InputLeftElement>
                <Input
                  id="pass"
                  focusBorderColor="purple.500"
                  type={showPass ? 'text' : 'password'}
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <InputRightElement>
                  <IconButton
                    icon={<FontAwesomeIcon icon={showPass ? ['fal', 'eye-slash'] : ['fal', 'eye']} />}
                    variant="ghost"
                    aria-label="show-pass"
                    size="sm"
                    onClick={togglePasswordVisibility}
                    title={`${showPass ? 'Hide' : 'Show'} Password`}
                  />
                </InputRightElement>
              </InputGroup>
            </FormControl>
          </Flex>

          <Button colorScheme="purple" type="submit" shadow="md" size="lg" isLoading={isLoading} width="100%">
            Sign in
          </Button>
          <Flex justifyContent="center" alignItems="center">
            <Link to="/password-reset">
              <Box as="span" ml="1" color="blue.500">
                Forgot password?
              </Box>
            </Link>
          </Flex>
          <Divider />
          <Flex width="100%" alignItems="center" justifyContent="center">
            <GoogleLogin
              onSuccess={async (credentialResponse) => {
                const data = await authService.loginGoogle({ token: credentialResponse.credential });
                setIsLoading(false);
                if (data.user?.id) {
                  auth.setUser(data.user);
                  const isOnboarded = await isUserOnboarded(data.user?.id);
                  setIsOnboarded(isOnboarded);
                  setIsAuthenticated(true);
                }
              }}
              onError={() => {
                console.log('Login Failed');
              }}
            />
          </Flex>
        </Stack>
      </form>
    </Authentication>
  );
};
