import { Skeleton, Stack } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchPollView } from '../viewer.service';
import { PollClosedView } from './PollClosedView';
import { PollViewerDetails } from './PollViewerDetails';
import { PollViewerPassword } from './PollViewerPassword';


export const PollViewer: React.FC = () => {
  const { pollId } = useParams<{ pollId: string }>();
  const [password, setPassword] = useState(sessionStorage.getItem(`videoPoll:${pollId}:password`)?.toString() ?? '');

  const {
    data: view,
    isLoading,
    isError,
    error,
    refetch,
  } = useQuery(['pollView', pollId], () => fetchPollView({ pollId: pollId!, password }), {
    retry: 0,
  });

  useEffect(() => {
    if (password.length > 0) {
      sessionStorage.setItem(`videoPoll:${pollId}:password`, password);
      refetch();
    }
  }, [password, pollId, refetch]);

  const passwordMissingOrWrongPassword = isError && (error as any)?.data?.statusCode === 400;
  const isPollClosed = (error as any)?.data?.statusCode === 410;

  if (isLoading) {
    return (
      <Stack>
        <Skeleton height="50px" width="100%" />
        <Skeleton height="50px" width="100%" />
        <Skeleton height="50px" width="100%" />
        <Skeleton height="50px" width="100%" />
      </Stack>
    );
  }

  if (isPollClosed) {
    return <PollClosedView />;
  }

  if (passwordMissingOrWrongPassword) {
    return <PollViewerPassword password={password} onSubmit={(password) => setPassword(password)} error={error} />;
  }

  if (view) {
    return <PollViewerDetails view={view} isLoading={isLoading} />;
  }

  return null;
};
