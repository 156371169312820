import React, { createContext, PropsWithChildren, useContext } from 'react';
import { User, UserSession } from '../types';
import { useProvideAuth } from './useProvideAuth';

type AuthSession = {
  fullName?: string;
  email: string;
  password: string;
};
type AuthContext = {
  user?: User | null;
  setUser: (user: User | null) => void;
  login: ({ email, password }: AuthSession) => Promise<UserSession>;
  signup: ({ fullName, email, password }: AuthSession) => Promise<UserSession>;
  logout: () => Promise<void>;
};
const authContext = createContext<AuthContext>({
  user: null,
  setUser: () => {},
  login: () => Promise.resolve({} as UserSession),
  signup: () => Promise.resolve({} as UserSession),
  logout: () => Promise.resolve(),
});

export const ProvideAuth: React.FC<PropsWithChildren> = ({ children }) => {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

export const useAuth = () => {
  return useContext(authContext);
};
