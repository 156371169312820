import React from 'react';
import { Button, Flex } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const NavigatingButtons: React.FC<{
  isLoading: boolean;
  step: number;
  numberOfQuestions: number;
  onPrevClick: () => any;
  onNextClick: () => any;
  onSubmit: () => any;
}> = ({ step, numberOfQuestions, onPrevClick, onNextClick, onSubmit, isLoading }) => {
  const isFirstStep = step === 1;
  const isLastStep = step === numberOfQuestions;

  return (
    <Flex mt={10} maxWidth="800px">
      <Button
        marginRight={10}
        disabled={isFirstStep}
        colorScheme="blackAlpha"
        background="black"
        color="white"
        leftIcon={<FontAwesomeIcon icon={['fal', 'arrow-left']} />}
        onClick={onPrevClick}
      >
        Previous
      </Button>
      <Button
        isLoading={isLoading}
        colorScheme="blackAlpha"
        background="black"
        color="white"
        rightIcon={isLastStep ? undefined : <FontAwesomeIcon icon={['fal', 'arrow-right']} />}
        onClick={() => (isLastStep ? onSubmit() : onNextClick())}
      >
        {isLastStep ? 'Submit' : 'Next'}
      </Button>
    </Flex>
  );
};
