import React from 'react';
import { useForm } from 'react-hook-form';
import { Box, Button, Flex, FormControl, Heading, Input, Stack, Text } from '@chakra-ui/react';

type Inputs = {
  password: string;
};

interface Props {
  password: string;
  error?: any;
  onSubmit: (password: string) => void;
}

export const PollViewerPassword: React.FC<Props> = ({ password, error, onSubmit }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({ defaultValues: { password } });

  const handleOnSubmit = ({ password }: Inputs) => {
    onSubmit(password);
  };

  return (
    <Flex height="100vh" width="100vw" flexDirection="column">
      <Flex padding={10} width="100%" flexDirection="column" alignItems="center">
        <Box w="md" p={10} pt={120} bg="white" borderRadius="md">
          <Stack spacing={4}>
            <Heading as="h1" size="lg" mb={5}>
              Poll is password protected
            </Heading>
            {error ? (
              <Text color="red.400" align="center">
                {error.data.message}
              </Text>
            ) : null}
            <form onSubmit={handleSubmit(handleOnSubmit)}>
              <Stack spacing={4}>
                <FormControl>
                  <Input
                    id="password"
                    {...register('password', { required: true })}
                    isInvalid={errors.password != null}
                    placeholder="Enter poll password"
                    focusBorderColor="purple.500"
                    type="password"
                  />
                  {errors.password && (
                    <Text fontSize="sm" color="red.500">
                      This field is required
                    </Text>
                  )}
                </FormControl>

                <Flex alignSelf="flex-end">
                  <Button colorScheme="purple" type="submit" shadow="md" size="lg">
                    Start
                  </Button>
                </Flex>
              </Stack>
            </form>
          </Stack>
        </Box>
      </Flex>
    </Flex>
  );
};
