import {
  Box,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger as OrigPopoverTrigger,
  Portal,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { ChromePicker } from 'react-color';

interface Props {
  isOpen?: boolean;
  defaultColor?: string;
  onClose?: () => void;
  onOpen?: () => void;
  onColorUpdate?: (color: string) => void;
}

// NOTE: workaround for types failing when building the project
export const PopoverTrigger: React.FC<React.PropsWithChildren<{ children: React.ReactNode }>> = OrigPopoverTrigger;

export const ColorPickerPopover: React.FC<React.PropsWithChildren<Props>> = ({
  isOpen,
  defaultColor,
  onOpen,
  onClose,
  onColorUpdate,
}) => {
  const [color, setColor] = useState<string>(defaultColor ?? '#6b46c1');

  return (
    <>
      <Popover isOpen={isOpen} onClose={onClose}>
        <PopoverTrigger>
          <Box as="button" mr={2} boxSize="40px" borderRadius="md" bgColor={color} onClick={onOpen} />
        </PopoverTrigger>
        <Portal>
          <PopoverContent bg="transparent" outline="none" _focus={{ border: 'none', outline: 'none' }} maxWidth="250px">
            <PopoverBody>
              <ChromePicker
                color={color}
                onChange={(color, e) => {
                  setColor(color.hex);
                  onColorUpdate?.(color.hex);
                }}
              />
            </PopoverBody>
          </PopoverContent>
        </Portal>
      </Popover>
    </>
  );
};
