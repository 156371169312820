import React, { useState } from 'react';
import { Authentication } from './Authentication';
import {
  Button,
  Flex,
  FormControl,
  Heading,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Stack,
  useToast,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { authService } from './api';
import { useNavigate, useParams } from 'react-router-dom';

export const ResetPasswordConfirm: React.FC = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const { token } = useParams<{ token: string }>();

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPass, setShowPass] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const handleChangePassword = async () => {
    if (password !== confirmPassword) {
      toast({
        title: 'Passwords do not match!',
        status: 'error',
        position: 'top-right',
        duration: 3210,
        isClosable: true,
      });

      return;
    }

    if (token == null) {
      toast({
        title: 'Invalid token!',
        status: 'error',
        position: 'top-right',
        duration: 3210,
        isClosable: true,
      });

      return;
    }

    setIsLoading(true);

    try {
      await authService.confirmResetPassword(token, password, confirmPassword);
      toast({
        title: 'Password reset successfully!',
        status: 'success',
        position: 'top-right',
        duration: 3210,
        isClosable: true,
      });

      setTimeout(() => {
        navigate('/login');
      }, 3000);
    } catch (error: any) {
      console.log(error);
      // user does not exist
      toast({
        title: 'Something went wrong!',
        status: 'error',
        position: 'top-right',
        duration: 3210,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPass(!showPass);
  };

  return (
    <Authentication>
      <Heading as="h2" color="gray.600" fontSize="lg">
        Enter the new password
      </Heading>
      <Stack spacing={4}>
        <Flex direction="column" gap={4} mb={6}>
          <FormControl isRequired>
            <InputGroup>
              <InputLeftElement>
                <FontAwesomeIcon icon={['fal', 'lock-alt']} />
              </InputLeftElement>
              <Input
                id="pass"
                focusBorderColor="purple.500"
                type={showPass ? 'text' : 'password'}
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <InputRightElement>
                <IconButton
                  icon={<FontAwesomeIcon icon={showPass ? ['fal', 'eye-slash'] : ['fal', 'eye']} />}
                  variant="ghost"
                  aria-label="show-pass"
                  size="sm"
                  onClick={togglePasswordVisibility}
                  title={`${showPass ? 'Hide' : 'Show'} Password`}
                />
              </InputRightElement>
            </InputGroup>
          </FormControl>
          <FormControl isRequired>
            <InputGroup>
              <InputLeftElement>
                <FontAwesomeIcon icon={['fal', 'lock-alt']} />
              </InputLeftElement>
              <Input
                id="pass"
                focusBorderColor="purple.500"
                type={showPass ? 'text' : 'password'}
                placeholder="Confirm Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <InputRightElement>
                <IconButton
                  icon={<FontAwesomeIcon icon={showPass ? ['fal', 'eye-slash'] : ['fal', 'eye']} />}
                  variant="ghost"
                  aria-label="show-pass"
                  size="sm"
                  onClick={togglePasswordVisibility}
                  title={`${showPass ? 'Hide' : 'Show'} Password`}
                />
              </InputRightElement>
            </InputGroup>
          </FormControl>
        </Flex>

        <Button
          colorScheme="purple"
          type="submit"
          shadow="md"
          size="lg"
          isLoading={isLoading}
          width="100%"
          onClick={handleChangePassword}
        >
          Confirm
        </Button>
      </Stack>
    </Authentication>
  );
};
