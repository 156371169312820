import React from 'react';
import { Flex } from '@chakra-ui/react';
import styles from './VideoPlayer.module.scss';

// TODO: Fix interfaces
interface Video {
  [key: string]: any;
}

export const VideoPlayer: React.FC<{ video?: Video; children?: React.ReactNode }> = ({ video, children }) => {
  return (
    <Flex justifyContent="center" alignItems="center" height="650px" className={styles.videoContainer}>
      <video className={styles.videoPreview} controls preload="metadata" autoPlay>
        <source src={video?.url} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className={styles.overlay}>{children}</div>
    </Flex>
  );
};
