import { Box, Image } from '@chakra-ui/react';
import React from 'react';

interface ProjectWithRelation {
  [key: string]: any;
}
export const CompanyLogo: React.FC<{ project?: ProjectWithRelation }> = ({ project }) => {
  return (
    <Box>
      {project?.branding?.logoUrl ? (
        <Image alt="logo" src={project?.branding?.logoUrl} maxHeight="60px" objectFit="cover" ml={10} />
      ) : (
        project?.name
      )}
    </Box>
  );
};
