import { ApiEndpoints, http } from '../Authentication/api';
import { User } from '../types';

const { ProfileUrl, SignupUrl } = ApiEndpoints;

type UserResponse = {
  data: User;
};
export const fetchCurrentUser = async (): Promise<User> => {
  const { data } = await http.get<User, UserResponse>(`${ProfileUrl}`).then((response: UserResponse) => response);
  return data;
};

export const updateUser = async (updatedUser: User) => {
  const { data } = await http
    .put<User, UserResponse>(`${SignupUrl}/${updatedUser.id}`, updatedUser)
    .then((response: UserResponse) => response);

  return data;
};
