import { Box, Button, Heading } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { verifyEmail } from './verifyEmail.service';
import { Header } from '../Header';

export const VerifyEmail: React.FC = () => {
  const navigate = useNavigate();
  const { token } = useParams<{ token: string }>();
  const { data } = useQuery(['email', token], () => verifyEmail(token!));

  return (
    <div>
      <Header />
      <Box width="100vw" height="100vh" display="flex" alignItems="center" justifyContent="center">
        <Box display="flex" flexDirection="column">
          <Heading as="h2" color="gray.600" fontSize="lg">
            Welcome {data?.fullName}! Your email is confirmed
          </Heading>

          <Button colorScheme="purple" shadow="md" size="md" marginTop={10} onClick={() => navigate('/')}>
            Go to the app
          </Button>
        </Box>
      </Box>
    </div>
  );
};
