import axios from 'axios';

const { REACT_APP_API_ENDPOINT } = process.env;

const headers: Readonly<Record<string, string>> = Object.freeze({
  Accept: 'application/json',
  'Content-Type': 'application/json; charset=UTF-8',
  'X-Requested-With': 'XMLHttpRequest',
});
const baseURL = `${REACT_APP_API_ENDPOINT}`;

// NOTE: Using this so we don't get interceptors from the http lib
export const authHttp = axios.create({ baseURL, headers });
