import React from 'react';
import { Image } from '@chakra-ui/react';
import iphoneFrame from '../../images/iphone-frame.png';
import styles from './../Steps.module.scss';

interface VideoProps {
  url: string;
  children?: React.ReactNode;
}

export const OnboardingVideo: React.FC<VideoProps> = (props: VideoProps) => {
  return (
    <div style={{ position: 'relative' }}>
      <Image src={iphoneFrame} alt="iPhone Frame" height="620px" style={{ position: 'absolute', top: 0, left: 0 }} />
      <video className={styles.video} controls preload="metadata" autoPlay>
        <source src={props.url} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      {props.children}
    </div>
  );
};
