import { Skeleton } from '@chakra-ui/react';
import React from 'react';
import { RouterProvider } from 'react-router-dom';
import { ProvideAuth } from './Authentication/ProvideAuth';
import { router } from './Routes/Routes';
import { GoogleOAuthProvider } from '@react-oauth/google';

const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

export const App: React.FC = () => (
  <React.StrictMode>
    <GoogleOAuthProvider clientId={CLIENT_ID!}>
      <ProvideAuth>
        <React.Suspense fallback={<Skeleton />}>
          <RouterProvider router={router} />
        </React.Suspense>
      </ProvideAuth>
    </GoogleOAuthProvider>
  </React.StrictMode>
);
