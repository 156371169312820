import { Box, Flex, Grid, GridItem, Image, Text } from '@chakra-ui/react';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { PollView } from '../viewer.service';
import { CompanyLogo } from './CompanyLogo';
import { OutroView } from './OutroView';
import { PollQuestionnaire } from './PollQuestionnaire';
import { ResultsView } from './ResultsView';
import { VideoPlayer } from './VideoPlayer';
import { Helmet } from 'react-helmet';

interface Props {
  view: PollView;
  isLoading: boolean;
}

export const PollViewerDetails: React.FC<Props> = ({ view, isLoading }) => {
  const { poll, project } = view;
  const video = poll?.videos?.length > 1 ? poll.videos[poll.videos.length - 1] : poll.videos[0];

  return (
    <Flex height="100vh" width="100vw" overflowY="auto" flexDirection="column">
      <Helmet>
        <title>{project?.name}</title>
        {/* <meta name="description" content="This is a description of my page" /> */}
        <meta property="og:title" content={project?.name} />
        {/* <meta property="og:description" content="This is a description of my page" /> */}
        <meta property="og:image" content={video?.thumbnailUrl} />
        {/* <meta property="og:url" content="https://example.com/my-page" /> */}
        <meta name="twitter:title" content={project?.name} />
        {/* <meta name="twitter:description" content="This is a description of my page" /> */}
        <meta name="twitter:image" content={video?.thumbnailUrl} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Box
        as="header"
        fontWeight="bold"
        fontSize="xl"
        height="80px"
        borderBottom="1px solid #d3d3d3"
        background={view?.project?.branding?.colorCode}
        padding={2}
      >
        <CompanyLogo project={view?.project} />
      </Box>
      <Grid templateColumns={{ base: 'repeat(1, 1fr)', lg: '34% 1fr' }} gap={4}>
        <Routes>
          <Route
            index
            element={
              <>
                <GridItem p={10}>
                  {!isLoading && (
                    <VideoPlayer video={video}>
                      <Flex
                        mt="10px"
                        border="1px"
                        borderColor="gray.100"
                        backgroundColor="gray.50"
                        boxSize="43px"
                        borderRadius="43px"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Box cursor="grab">
                          {view?.poll?.project?.user?.profilePictureUrl && (
                            <Image
                              alt="logo"
                              src={view?.poll?.project?.user?.profilePictureUrl || ''}
                              boxSize="43px"
                              objectFit="cover"
                              borderRadius="43px"
                            />
                          )}
                        </Box>
                      </Flex>
                      <Text color="white" fontSize={12} fontWeight={700}>
                        {view?.poll?.project?.user?.fullName}
                      </Text>
                      <Text color="white" fontSize={12} fontWeight={400}>
                        {view?.poll?.project?.user?.email}
                      </Text>
                    </VideoPlayer>
                  )}
                </GridItem>
                <GridItem p={10}>
                  {view.participant ? (
                    <PollQuestionnaire participant={view.participant} questions={poll.questions as any} />
                  ) : null}
                </GridItem>
              </>
            }
          />
          <Route
            path="outro"
            element={
              poll.outros ? (
                <OutroView
                  outros={poll.outros as any}
                  shouldShowResults={poll.settings?.shareResultsWithRecipients || false}
                />
              ) : null
            }
          />
          <Route path="results" element={<ResultsView />} />
        </Routes>
      </Grid>
    </Flex>
  );
};
