import {
  Box,
  Button,
  Flex,
  FormControl,
  Input,
  LinkBox,
  LinkOverlay,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Stack,
  Text,
  useDisclosure,
  useMediaQuery,
  Image,
  Textarea,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMutation, useQuery } from '@tanstack/react-query';
import { format, parseISO } from 'date-fns';
import { useForm } from 'react-hook-form';
import { Link as RLink, useParams } from 'react-router-dom';
import { Poll } from '../../types';
import { MediaQuery } from '../../utils/MediaQuery';
import { createProjectPoll, fetchProjectPolls } from '../projects.service';

type Inputs = {
  name: string;
  description?: string;
};

interface ModalProps {
  isOpen: boolean;
  isLoading: boolean;
  onCreatePoll: (inputs: Inputs) => void;
  onClose: () => void;
}
const CreateNewPollModal: React.FC<ModalProps> = ({ isOpen, onClose, onCreatePoll, isLoading }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({ defaultValues: { name: '', description: '' } });

  return (
    <Modal isOpen={isOpen} onClose={onClose} autoFocus>
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={handleSubmit(onCreatePoll)}>
          <ModalHeader>Create Video Poll</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={10}>
              <FormControl>
                <Input
                  {...register('name', { required: true })}
                  id="name"
                  autoFocus
                  isInvalid={errors.name != null}
                  placeholder="Name your poll"
                  focusBorderColor="purple.500"
                  type="text"
                />
                <Textarea
                  {...register('description', { required: false })}
                  id="description"
                  placeholder="Describe the goal of your poll"
                  focusBorderColor="purple.500"
                  mt={5}
                />
                {errors.name && (
                  <Text fontSize="sm" color="red.500">
                    This field is required
                  </Text>
                )}
              </FormControl>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button colorScheme="purple" type="submit" isLoading={isLoading}>
              Save
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

const PollVideoThumbnail: React.FC<{ poll: Poll }> = ({ poll }) => {
  const hasVideoThumbnail = poll?.videos?.length > 0 && poll?.videos[0]?.thumbnailUrl != null;
  if (!hasVideoThumbnail) {
    return (
      <Box bg="gray.200" height="90%" width="auto" display="flex" alignItems="center" justifyContent="center">
        <FontAwesomeIcon icon={['fal', 'film']} size="6x" color="#c7d0db" />
      </Box>
    );
  }

  return (
    <Image src={poll?.videos[0]?.thumbnailUrl} height="90%" width="auto" objectFit="cover" alt="Poll Video Thumbnail" />
  );
};

const PollsList: React.FC = () => {
  const [isLargeScreen] = useMediaQuery(MediaQuery.XLarge);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { projectId } = useParams<{ projectId: string }>();
  const {
    data: polls,
    isLoading,
    refetch,
  } = useQuery(['project-polls', projectId], () => fetchProjectPolls(projectId!));

  const { mutateAsync, isLoading: isSaving } = useMutation(createProjectPoll);

  const onCreatePoll = async ({ name, description }: Inputs) => {
    try {
      const poll = await mutateAsync({ name, description, projectId: projectId! });
      if (poll.id) {
        refetch();
        onClose();
      }
    } catch (error: any) {
      console.log('::Error Creating Poll::');
      console.error(error);
    }
  };

  return (
    <Flex display="flex" flexWrap="wrap" gap="3">
      <Box
        p="5"
        height="430px"
        width="250px"
        borderWidth="1px"
        display="flex"
        justifyContent="center"
        alignItems="center"
        fontSize="1.2rem"
      >
        <Button
          onClick={onOpen}
          color="purple.500"
          leftIcon={<FontAwesomeIcon icon={['fal', 'plus']} />}
          variant="ghost"
        >
          New Video Poll
        </Button>
      </Box>
      {isLoading ? (
        <>
          <Skeleton height="430px" width="250px" />
          <Skeleton height="430px" width="250px" />
          <Skeleton height="430px" width="250px" />
        </>
      ) : null}
      {polls?.map((poll) => (
        <LinkBox
          key={poll.id}
          border="1px"
          borderColor="gray.200"
          height="430px"
          width="250px"
          display="flex"
          flexDirection="column"
        >
          <PollVideoThumbnail poll={poll} />

          <Box p={2} color="gray.400">
            <LinkOverlay as={RLink} to={`/polls/${poll.id}`} color="gray.600" fontSize="small">
              <Text fontWeight="bold">{poll.name}</Text>
            </LinkOverlay>
            <Text fontSize="xs">{format(parseISO(poll.createdAt as unknown as string), 'yyyy-MM-dd')}</Text>
          </Box>
        </LinkBox>
      ))}
      {polls && polls?.length <= 3 ? (
        isLargeScreen ? (
          <>
            <Box height="430px" width="250px" />
            <Box height="430px" width="250px" />
            <Box height="430px" width="250px" />
            <Box height="430px" width="250px" />
            <Box height="430px" width="250px" />
          </>
        ) : (
          <>
            <Box height="430px" width="250px" />
            <Box height="430px" width="250px" />
          </>
        )
      ) : null}
      {isOpen ? (
        <CreateNewPollModal isOpen={isOpen} onClose={onClose} onCreatePoll={onCreatePoll} isLoading={isSaving} />
      ) : null}
    </Flex>
  );
};

export default PollsList;
