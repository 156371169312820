import { AxiosRequestConfig } from 'axios';
import { ApiEndpoints, http } from '../Authentication/api';

type LogoUploadResponse = {
  data: string;
};

export const uploadFile = async (file: File, url: string): Promise<string> => {
  const formData = new FormData();
  formData.append('files', file);
  formData.append('entity', 'Logo');

  const headers = {
    'Content-Type': 'multipart/form-data',
  };

  const { data } = await http
    .post<FormData, LogoUploadResponse>(`${ApiEndpoints.FileManagerUrl}/${url}`, formData, {
      headers: headers as unknown as AxiosRequestConfig['headers'],
    })
    .then((response) => response);
  return data;
};
