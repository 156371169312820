import React from 'react';
import { Link } from 'react-router-dom';
import { Avatar, Flex, Heading, Text, Wrap, WrapItem } from '@chakra-ui/react';
import { useAuth } from './Authentication/ProvideAuth';
import { Logo } from './Logo/components/Logo';

export const Header: React.FC = () => {
  const { user } = useAuth();

  return (
    <Flex
      as="header"
      pos="fixed"
      direction="row"
      width="100%"
      align="center"
      justify="space-between"
      padding="0.8rem"
      bg="white"
      borderBottom="2px"
      borderBottomColor="gray.200"
      zIndex="999"
    >
      <Heading as="h2" color="purple.500" className="logo-text">
        <Logo />
      </Heading>

      <Link to="/profile">
        <Wrap>
          <WrapItem>
            <Avatar name={`${user?.fullName}`} src={user?.profilePictureUrl} size="sm" />
          </WrapItem>
          <WrapItem alignItems="center" justifyContent="center">
            <Text color="gray.500">{user?.fullName}</Text>
          </WrapItem>
        </Wrap>
      </Link>
    </Flex>
  );
};
