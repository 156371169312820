import { Experience, GlobalState, OnboardingPayload, Organisation } from 'little-state-machine';
import { ApiEndpoints, http } from '../Authentication/api';

const { SignupUrl } = ApiEndpoints;

export default function updateOnboarding(state: GlobalState, payload: OnboardingPayload) {
  return payload
    ? {
        ...state,
        onboarding: {
          ...state.onboarding,
          ...payload,
          branding: {
            ...state.onboarding.branding,
            ...payload.branding,
          },
        },
      }
    : state;
}

export function resetOnboarding(state: GlobalState) {
  return {
    ...state,
    onboarding: {
      experience: 'star' as Experience,
      organisation: 'student' as Organisation,
      branding: {
        name: undefined,
        role: undefined,
        companyName: undefined,
        brandingColor: '#6b46c1',
        profilePicture: undefined,
        companyLogo: undefined,
      },
    },
  };
}

export const completeOnboarding = async (
  userId: string,
  projectId: string,
  branding: OnboardingPayload
): Promise<any> => {
  const { data } = await http
    .post(`${SignupUrl}/${userId}/onboarding`, { projectId, onboarding: branding })
    .then((response: any) => response);
  return data;
};

export const isUserOnboarded = async (userId: string): Promise<boolean> => {
  const { data } = await http.get(`${SignupUrl}/${userId}/onboarding`).then((response: any) => response);
  return data;
};
