import React, { useEffect, useState } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router';
import { Text } from '@chakra-ui/react';
import { saveParticipantAnswers } from '../viewer.service';
import { NavigatingButtons } from './NavigatingButtons';
import { QuestionStep } from './QuestionStep';

// TODO: Fix interfaces
interface Answer {
  [key: string]: any;
}
interface Participant {
  [key: string]: any;
}
interface Poll {
  [key: string]: any;
}
interface Question {
  [key: string]: any;
}

type QuestionMeAnswers = Question & { poll: Poll; answers: Answer[] };
const normalizeAnswers = (questionWithAnswers: { questions: QuestionMeAnswers[] }): string[] => {
  const { questions } = questionWithAnswers;

  return questions
    ?.map(({ answers }) => {
      /**
      When using multiple answers we get structure like the following
      {
        "645c9ab6-6fb5-4a5e-9fac-840d4d2099d9": true,
        "9cb16e8d-38cd-449c-9efb-4fd1ba5c7124": false,
        "2bf6ed94-8e1a-4a02-837a-6b17e29ba11c": false
      }
      The selected answers are set to true so we need to extract those ids
     */
      if (typeof answers === 'object') {
        return Object.keys(answers)
          ?.map((key: string) => ((answers as any)[key] === true ? key : ''))
          .filter((q) => q);
      }

      // when using radios the answer is string so return directly
      return answers as string;
    })
    .flat();
};
interface Props {
  participant: Participant;
  questions: QuestionMeAnswers[];
}
export const PollQuestionnaire: React.FC<Props> = ({ participant, questions }) => {
  const navigate = useNavigate();
  const { pollId } = useParams<{ pollId: string }>();
  const [questionIndex, setQuestionIndex] = useState(0);
  const [step, setStep] = useState(1);
  const numberOfQuestions = questions.length ?? 0;
  const form = useForm({
    defaultValues: { questions },
  });
  const { isLoading, mutateAsync } = useMutation(saveParticipantAnswers);

  const onSaveAnswers = async (answerIds: string[]) => {
    try {
      const data = await mutateAsync({ participant, answerIds });
      if (data) {
        navigate(`/view/${pollId}/outro`);
      }
    } catch (error: any) {
      console.log('::Error Saving Answers::');
      console.error(error);
    }
  };
  useFieldArray({
    name: 'questions',
    control: form.control,
  } as any);

  useEffect(() => {
    const nextStep = step - 1;
    setQuestionIndex(nextStep);
  }, [step, questions]);

  return (
    <>
      <FormProvider {...form}>
        <Text color="gray.400">
          Question {step} of {numberOfQuestions}
        </Text>

        <QuestionStep qIndex={questionIndex} question={questions[questionIndex]} />

        <NavigatingButtons
          isLoading={isLoading}
          step={step}
          numberOfQuestions={numberOfQuestions}
          onPrevClick={() => {
            const prevStep = step <= 1 ? 1 : step - 1;
            setStep(prevStep);
          }}
          onNextClick={() => {
            const nextStep = step >= numberOfQuestions - 1 ? numberOfQuestions : step + 1;
            setStep(nextStep);
          }}
          onSubmit={() => {
            const answers = normalizeAnswers(form.getValues());
            onSaveAnswers(answers);
          }}
        />
      </FormProvider>
    </>
  );
};
