export enum ApiEndpoints {
  AuthenticateUrl = 'auth/login',
  AuthenticateGoogleUrl = 'google/login',
  LogoutUrl = 'auth/logout',
  OutrosUrl = 'outros',
  PollsUrl = 'polls',
  PollViewUrl = 'view',
  PollResultsUrl = 'results',
  ParticipantsUrl = 'participants',
  ProfileUrl = 'profile',
  QuestionsUrl = 'questions',
  RecipientsUrl = 'recipients',
  SignupUrl = 'users',
  VideosUrl = 'videos',
  ProjectsUrl = 'projects',
  FileManagerUrl = 'file-manager',
}
