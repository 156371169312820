import { Box } from '@chakra-ui/react';
import React from 'react';

export const PrivacyPolicy: React.FC = () => {
  return (
    <Box p={20}>
      <p className="c7">
        <span className="c4">1. GENERAL</span>
      </p>
      <ul className="c6 lst-kix_list_1-0 start">
        <li className="c0 li-bullet-0">
          <span className="c11">1.1</span>
          <span className="c1">&nbsp;These Terms of Service (the &rdquo;</span>
          <span className="c11">TOS</span>
          <span className="c1">&rdquo;) are applicable in relation to Expensia AB&rsquo;s (&ldquo;</span>
          <span className="c11">Video poll</span>
          <span className="c1">
            &rdquo;), corporate registration number 559061-0522, with registered address at Duvv&auml;gen 3, 352 50
            V&auml;xj&ouml;, Sweden, provision of a service for polls using video (the &ldquo;
          </span>
          <span className="c11">Service</span>
          <span className="c1 c10">&rdquo;).</span>
        </li>
        <li className="c7 c12 li-bullet-0">
          <span className="c11">1.2</span>
          <span className="c1 c10">
            &nbsp;In order to obtain access to the Service you must accept to be bound by these TOS. If you do not
            accept these TOS you may not use the Service and VideoPoll reserves the right to block you from the Service.
          </span>
        </li>
      </ul>
      <p className="c7">
        <span className="c4">2. THE SERVICE</span>
      </p>
      <ul className="c6 lst-kix_list_2-0 start">
        <li className="c0 li-bullet-0">
          <span className="c11">2.1</span>
          <span className="c1">
            &nbsp;In order to access the Service you register an account at www.poll.video (the &ldquo;
          </span>
          <span className="c11">App</span>
          <span className="c1 c10">&rdquo;). Further you will need to link your e-mail to the Service.</span>
        </li>
      </ul>
      <p className="c2">
        <span className="c1 c10"></span>
      </p>
      <p className="c7">
        <span className="c4">3. REGISTRATION</span>
      </p>
      <ul className="c6 lst-kix_list_3-0 start">
        <li className="c0 li-bullet-0">
          <span className="c11">3.1</span>
          <span className="c1">
            &nbsp;Upon your registration you are required to provide the following mandatory information (&ldquo;
          </span>
          <span className="c11">User Information</span>
          <span className="c1 c10">&rdquo;):</span>
        </li>
        <li className="c5 li-bullet-0">
          <span className="c1 c10"></span>
        </li>
      </ul>
      <ul className="c6 lst-kix_list_3-1 start">
        <li className="c9 li-bullet-0">
          <span className="c1 c10">(1) Full name</span>
        </li>
        <li className="c9 li-bullet-0">
          <span className="c1 c10">(2) Email address belonging to you as a user</span>
        </li>
      </ul>
      <p className="c14 c15">
        <span className="c1 c10"></span>
      </p>
      <p className="c8">
        <span className="c1 c10"></span>
      </p>
      <ul className="c6 lst-kix_list_3-0">
        <li className="c14 c12 li-bullet-0">
          <span className="c11">3.2</span>
          <span className="c1 c10">
            &nbsp;You are required to inform Video poll in case any User Information changes. Video poll reserves the
            right to deny or cancel your registration in case any User Information is found to be incorrect.
          </span>
        </li>
        <li className="c7 c12 li-bullet-0">
          <span className="c11">3.3</span>
          <span className="c1 c10">
            &nbsp;Video poll will be data controller of your personal information and solely responsible for the
            processing of such information in accordance with your request and consent. Video poll will remove all
            information connected to your account when you cancel the account.
          </span>
        </li>
      </ul>
      <p className="c7">
        <span className="c4">4. USE OF THE SERVICE</span>
      </p>
      <ul className="c6 lst-kix_list_4-0 start">
        <li className="c0 li-bullet-0">
          <span className="c11">4.1</span>
          <span className="c1 c10">
            &nbsp;You are required to follow and adhere to the guidelines and regulations of the Service
          </span>
        </li>
        <li className="c0 li-bullet-0">
          <span className="c11">4.2</span>
          <span className="c1 c10">
            &nbsp;The Service requires that you enter a correct email address that will be verified.
          </span>
        </li>
        <li className="c7 c12 li-bullet-0">
          <span className="c11">4.3</span>
          <span className="c1 c10">
            &nbsp;It is strictly prohibited to copy, modify, distribute or publish content that is provided by Video
            poll.
          </span>
        </li>
      </ul>
      <p className="c7">
        <span className="c4">6. FEES</span>
      </p>
      <ul className="c6 lst-kix_list_6-0 start">
        <li className="c0 li-bullet-0">
          <span className="c11">6.1</span>
          <span className="c1 c10">
            &nbsp;Your use of the Service can be free of charge, but we also offer paid packages. Fees to your mobile
            operator or Internet supplier are charged separately without any involvement by Video poll.
          </span>
        </li>
        <li className="c7 c12 li-bullet-0">
          <span className="c11">6.2</span>
          <span className="c1 c10">
            &nbsp;The Issuer of your card may charge separate fees when paying for the service.
          </span>
        </li>
      </ul>
      <p className="c7">
        <span className="c4">7. CUSTOMER SERVICES AND COMPLAINTS</span>
      </p>
      <ul className="c6 lst-kix_list_7-0 start">
        <li className="c12 c13 li-bullet-0">
          <span className="c1 c10">
            Customer services and complaints are handled through email and the address bottomsup@poll.video. A
            particular matter is regarded as received by Video poll when the matter has been submitted in a correct
            manner and clearly states the name of the user and relevant information about the matter at hand.
          </span>
        </li>
      </ul>
      <p className="c7">
        <span className="c4">8. TERM AND TERMINATION</span>
      </p>
      <ul className="c6 lst-kix_list_8-0 start">
        <li className="c0 li-bullet-0">
          <span className="c11">8.1</span>
          <span className="c1 c10">
            &nbsp;You are entitled to cease using the Service at any time by unsubscribing the Service on
            www.poll.video. Video poll will then erase your personal information from its systems and anonymize all
            existing Transaction Data.
          </span>
        </li>
        <li className="c7 c12 li-bullet-0">
          <span className="c11">8.2</span>
          <span className="c1 c10">
            &nbsp;Video Poll reserves the right to suspend or terminate your access to the Service in case of any misuse
            of the Service, any violation of these TOS or in case your use of the Service, in Video poll&acute;s sole
            opinion, may cause harm to Video pol, or any third party. In case Video poll detects a repeated fraudulent
            use of the Services, Video Poll reserves the right to report such behaviour to relevant authorities.
          </span>
        </li>
      </ul>
      <p className="c7">
        <span className="c4">9. LIABILITY</span>
      </p>
      <ul className="c6 lst-kix_list_9-0 start">
        <li className="c0 li-bullet-0">
          <span className="c11">9.1</span>
          <span className="c1 c10">
            &nbsp;Video poll&rsquo;s ambition is that the Service will function properly and that it will be
            continuously updated. However, Video poll does not guarantee that the Service will be accessible at all
            times and Video poll assumes no liability in case the Service is temporarily out of order.
          </span>
        </li>
        <li className="c14 c12 li-bullet-0">
          <span className="c11">9.2</span>
          <span className="c1 c10">
            &nbsp;Video poll does not assume any liability for any direct or indirect costs that may arise out of the
            use of the Service.
          </span>
        </li>
        <li className="c7 c12 li-bullet-0">
          <span className="c11">9.3</span>
          <span className="c1 c10">
            &nbsp;The user is liable for any damages that are caused to Video poll through a breach of these TOS or
            misuse of the Service.
          </span>
        </li>
      </ul>
      <p className="c7">
        <span className="c4">10. PERSONAL DATA</span>
      </p>
      <ul className="c6 lst-kix_list_10-0 start">
        <li className="c0 li-bullet-0">
          <span className="c11">10.1</span>
          <span className="c1 c10">
            &nbsp;When you use the Service, Video poll will collect and process your personal data in the way described
            under this section 10. Your personal integrity and the security of your personal data is of the utmost
            importance to Video poll. It is therefore a priority for Video poll that your personal data is protected and
            only processed in a way that you feel comfortable with. In light hereof, Video poll only saves data about
            you/your company that is necessary for the service to work.
          </span>
        </li>
        <li className="c12 c14 li-bullet-0">
          <span className="c11">10.2</span>
          <span className="c1 c10">
            &nbsp;Video Poll collects and processes your personal data when you use the Service and register an account.
            Video Poll is responsible for your personal data in accordance with applicable data protection legislation.
            The personal data that Video poll collects and processes is your User Information, Your personal data is
            processed by Video poll for the purpose of providing, developing and improving the Service, analysing your
            Transaction Data and communicating with you. Video Poll may use your personal data to provide offers and
            information about the Service. You may at any time unsubscribe from such messages.
          </span>
        </li>
        <li className="c14 c12 li-bullet-0">
          <span className="c11">10.3</span>
          <span className="c1 c10">
            &nbsp;The personal data that we collect may be shared with other companies in the Video poll group to
            provide the Service, for the processing on our behalf or for the centralization of data. Lastly, we may
            share your personal data when this is required by law, as well as to protect the security of our services
            and our rights. Otherwise Video poll does not transfer any personal data to any third party.
          </span>
        </li>
        <li className="c14 c12 li-bullet-0">
          <span className="c11">10.4</span>
          <span className="c1">
            &nbsp;Once per year you are entitled to receive a report with information about which personal data Video
            poll has registered about you. You can receive this information by sending a request to Video Poll
          </span>
          <span className="c1">&rsquo;s</span>
          <span className="c1 c10">
            &nbsp;address stated above. Such a request must be made in writing and signed by you. You are entitled to
            have your personal data corrected or erased. If you request your personal data to be erased, the Service
            will not function as intended.
          </span>
        </li>
        <li className="c14 c12 li-bullet-0">
          <span className="c11">10.5</span>
          <span className="c1 c10">
            &nbsp;Video poll is using DigitalOcean as a storage provider and the data is stored in Frankfurt.
          </span>
        </li>
      </ul>
      <p className="c3">
        <span className="c1 c10"></span>
      </p>
      <ul className="c6 lst-kix_list_10-0">
        <li className="c14 c12 li-bullet-0">
          <span className="c11">10.6</span>
          <span className="c1 c10">
            &nbsp;Video poll collects data about your mobile device (not launched yet) on which the App is installed,
            such as model, operating system and geographical location. This information is used for statistical purposes
            and in order to give Merchants the opportunity to direct customised offers to you to improve your customer
            experience.
          </span>
        </li>
        <li className="c14 c12 li-bullet-0">
          <span className="c11">10.7</span>
          <span className="c1 c10">
            &nbsp;Video poll uses Google Analytics or similar tools to produce anonymized statistics regarding the
            general use of the Service. This information cannot be linked to you personally and Video poll does not
            provide Google with any User Information.
          </span>
        </li>
        <li className="c7 c12 li-bullet-0">
          <span className="c11">10.8</span>
          <span className="c1 c10">
            &nbsp;By registering as a user of the Service you consent to the processing of your personal data as
            described in this Section 10.
          </span>
        </li>
      </ul>
      <p className="c7">
        <span className="c4">11. COOKIES</span>
      </p>
      <ul className="c6 lst-kix_list_11-0 start">
        <li className="c13 c12 li-bullet-0">
          <span className="c1 c10">
            Video Poll uses cookies on the Site in order to improve your customer experience when using the Service. If
            you set your mobile device or your web browser not to accept cookies the Service will not function as
            intended.
          </span>
        </li>
      </ul>
      <p className="c7">
        <span className="c4">12. INTELLECTUAL PROPERTY</span>
      </p>
      <ul className="c6 lst-kix_list_12-0 start">
        <li className="c13 c12 li-bullet-0">
          <span className="c1 c10">
            The trademarks and other intellectual property on the Site is protected by local and international laws. Any
            unauthorised use of this intellectual property is strictly prohibited and may entail liability for damages.
          </span>
        </li>
      </ul>
      <p className="c7">
        <span className="c4">13. CHANGES OF TOS</span>
      </p>
      <ul className="c6 lst-kix_list_13-0 start">
        <li className="c13 c12 li-bullet-0">
          <span className="c1 c10">
            Video poll reserves the right to change these TOS at any time. You will be informed of any changes hereof in
            advance and a change will enter into force when accepted by the user. If you do not accept a change of the
            TOS, you are no longer entitled to use the Service. Video Poll further reserves the right, without any prior
            notice, to change the contents in the App and on the Site and the functionality of the Service.
          </span>
        </li>
      </ul>
      <p className="c7">
        <span className="c4">14. DISPUTES</span>
      </p>
      <ul className="c6 lst-kix_list_14-0 start">
        <li className="c13 c12 li-bullet-0">
          <span className="c1">Any </span>
          <span className="c1">dispute in</span>
          <span className="c1 c10">
            &nbsp;connection with these TOS and your use of the Service shall be settled by the ordinary courts of
            Sweden.
          </span>
        </li>
      </ul>
      <p className="c15 c16" id="h.gjdgxs">
        <span className="c10 c17"></span>
      </p>
    </Box>
  );
};
