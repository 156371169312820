import { Box } from '@chakra-ui/react';
import React from 'react';

import { ReactComponent as Error500 } from './internal-server-error.svg';

export const ErrorElement: React.FC = () => {
  return (
    <Box width="100vw" height="100vh" display="flex" alignItems="center" justifyContent="center">
      <Box width="50vw">
        <Error500 />
      </Box>
    </Box>
  );
};
