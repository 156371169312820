import React, { useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Stack,
  Switch,
  Text,
} from '@chakra-ui/react';
import { Authentication } from './Authentication';
import { useAuth } from './ProvideAuth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GoogleLogin } from '@react-oauth/google';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { authService } from './api';
import { isUserOnboarded } from '../Onboarding/updateOnboarding';

const schema = z.object({
  fullName: z.string().min(2, 'Full name must be at least 2 characters'),
  email: z.string().email(),
  password: z.string().min(8, 'Password must be at least 8 characters'),
  tos: z.boolean().refine((value) => value, 'You must agree to the Terms of Service'),
  privacyPolicy: z.boolean().refine((value) => value, 'You must agree to the Privacy Policy'),
});
interface FormValues {
  fullName: string;
  email: string;
  password: string;
  tos: boolean;
  privacyPolicy: boolean;
}
export const SignupPage: React.FC = () => {
  const auth = useAuth();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({ resolver: zodResolver(schema) });
  const [isAuthenticated, setIsAuthenticated] = useState(auth.user ?? false);
  const [isOnboarded, setIsOnboarded] = useState(false);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showPass, setShowPass] = useState(false);

  const signup = async ({ fullName, email, password, tos, privacyPolicy }: FormValues) => {
    try {
      setIsLoading(true);
      await auth.signup({ fullName, email, password });
      setIsLoading(false);
      setIsAuthenticated(true);
    } catch (error: any) {
      setError(error?.response?.data?.message);
      setIsLoading(false);
      setIsAuthenticated(false);
    }
  };

  const togglePasswordVisibility = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setShowPass(!showPass);
  };

  if (isAuthenticated) {
    // sometimes the user already has an account but goes to the signup page
    const goTo = isOnboarded ? '/projects' : '/onboarding';
    return <Navigate to={goTo} />;
  }

  return (
    <Authentication>
      <Heading as="h2" color="gray.600" fontSize="lg">
        Create account
      </Heading>
      {error ? (
        <Text color="red.400" align="center">
          {error}
        </Text>
      ) : null}
      <form onSubmit={handleSubmit(signup)}>
        <Stack spacing={4}>
          <Flex direction="column" gap={4}>
            <FormControl isRequired isInvalid={errors.fullName != null}>
              <InputGroup>
                <InputLeftElement>
                  <FontAwesomeIcon icon={['fal', 'user']} />
                </InputLeftElement>
                <Input
                  id="fullName"
                  placeholder="Full Name"
                  focusBorderColor="purple.500"
                  type="text"
                  {...register('fullName')}
                />
              </InputGroup>
              <FormErrorMessage>{errors.fullName && errors.fullName.message}</FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={errors.email != null}>
              <InputGroup>
                <InputLeftElement>
                  <FontAwesomeIcon icon={['fal', 'envelope']} />
                </InputLeftElement>
                <Input
                  id="email"
                  placeholder="Email Address"
                  focusBorderColor="purple.500"
                  type="email"
                  {...register('email')}
                />
              </InputGroup>
              <FormErrorMessage>{errors.email && errors.email.message}</FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={errors.password != null}>
              <InputGroup>
                <InputLeftElement>
                  <FontAwesomeIcon icon={['fal', 'lock-alt']} />
                </InputLeftElement>
                <Input
                  id="pass"
                  type={showPass ? 'text' : 'password'}
                  placeholder="Password"
                  focusBorderColor="purple.500"
                  {...register('password')}
                />
                <InputRightElement>
                  <IconButton
                    icon={
                      showPass ? (
                        <FontAwesomeIcon icon={['fal', 'eye-slash']} />
                      ) : (
                        <FontAwesomeIcon icon={['fal', 'eye']} />
                      )
                    }
                    variant="ghost"
                    aria-label="show-pass"
                    size="sm"
                    onClick={togglePasswordVisibility}
                    title={`${showPass ? 'Hide' : 'Show'} Password`}
                  />
                </InputRightElement>
              </InputGroup>
              <FormErrorMessage>{errors.password && errors.password.message}</FormErrorMessage>
            </FormControl>
            <Flex direction="column">
              <FormControl display="flex" alignItems="center" justifyContent="space-between">
                <FormLabel htmlFor="tos" mb="0">
                  I agree to Video Poll's{' '}
                  <Link to="/terms-of-service">
                    <Box as="span" ml="1" color="blue.500">
                      Terms of Service
                    </Box>
                  </Link>
                </FormLabel>
                <Switch id="tos" colorScheme="purple" {...register('tos')} />
              </FormControl>
              <FormControl
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                isInvalid={errors.privacyPolicy != null}
              >
                <FormLabel htmlFor="privacyPolicy" mb="0">
                  I agree to Video Poll's{' '}
                  <Link to="/privacy-policy">
                    <Box as="span" ml="1" color="blue.500">
                      Privacy Policy
                    </Box>
                  </Link>
                </FormLabel>
                <Switch id="privacyPolicy" colorScheme="purple" {...register('privacyPolicy')} />
              </FormControl>
            </Flex>
            <Flex direction="column">
              {errors.tos && <Text color="red.500">{errors.tos.message}</Text>}
              {errors.privacyPolicy && <Text color="red.500">{errors.privacyPolicy.message}</Text>}
            </Flex>
          </Flex>
          <Button colorScheme="purple" type="submit" shadow="md" size="lg" isLoading={isLoading}>
            Sign up
          </Button>
          <Text color="gray.500" fontSize="md" textAlign="left">
            Already have a Video Poll account?
            <Link to="/login">
              <Box as="span" ml="1" color="blue.500">
                Sign in
              </Box>
            </Link>
          </Text>
          <Divider />
          <Flex width="100%" alignItems="center" justifyContent="center">
            <GoogleLogin
              onSuccess={async (credentialResponse) => {
                // TODO: Handle TOS and Privacy Policy
                const data = await authService.loginGoogle({ token: credentialResponse.credential });
                setIsLoading(false);
                if (data.user?.id) {
                  auth.setUser(data.user);
                  const isOnboarded = await isUserOnboarded(data.user?.id);
                  setIsOnboarded(isOnboarded);
                  setIsAuthenticated(true);
                }
              }}
              onError={() => {
                console.log('Login Failed');
              }}
            />
          </Flex>
        </Stack>
      </form>
    </Authentication>
  );
};
