import React, { useEffect } from 'react';
import { Box, Flex, GridItem, useToast, Image, Button } from '@chakra-ui/react';
import { useFilePicker } from 'use-file-picker';
import { uploadFile } from '../file.service';

interface Props {
  imageUrl?: string;
  uploadEndpoint: string;
  hasCircularArea?: boolean;
  onFileSelectorOpen?: () => void;
  onUpload: (logoUrl: string) => void;
}

export const FileUploader: React.FC<Props> = ({
  imageUrl,
  uploadEndpoint,
  hasCircularArea,
  onFileSelectorOpen,
  onUpload,
}) => {
  const toast = useToast();

  const { openFilePicker, plainFiles } = useFilePicker({
    readAs: 'DataURL',
    accept: 'image/*',
    multiple: false,
  });

  const handleFirstOpenFileSelector = () => {
    if (onFileSelectorOpen) {
      // we might want to do something when the
      // user initially opens the file selector
      onFileSelectorOpen();
    }

    openFilePicker();
  };

  useEffect(() => {
    const handleUploadLogo = async () => {
      try {
        const logoDigitalOceanUrl = await uploadFile(plainFiles[0], uploadEndpoint);
        onUpload(logoDigitalOceanUrl);
      } catch (error) {
        toast({
          title: 'Something went wrong!',
          status: 'error',
          position: 'top-right',
          duration: 3210,
          isClosable: true,
        });
      }
    };

    if (plainFiles.length === 0 || plainFiles[0] == null) {
      return;
    }

    handleUploadLogo();
  }, [plainFiles, uploadEndpoint]); // eslint-disable-line

  return (
    <GridItem colSpan={1}>
      <Flex
        mt="10px"
        border="1px"
        borderColor="gray.100"
        backgroundColor="gray.50"
        boxSize="200px"
        borderRadius={hasCircularArea ? '200px' : 0}
        alignItems="center"
        justifyContent="center"
      >
        {imageUrl != null && imageUrl.length > 0 ? (
          <Box cursor="grab">
            <Image
              alt="logo"
              src={imageUrl}
              boxSize="200px"
              objectFit="cover"
              mt="20px"
              borderRadius={hasCircularArea ? '200px' : 0}
            />
            <br />
            <Button size="sm" position="absolute" bottom="10px" right="5px" onClick={openFilePicker}>
              Update
            </Button>
          </Box>
        ) : (
          <Button colorScheme="purple" variant="outline" type="submit" size="sm" onClick={handleFirstOpenFileSelector}>
            Upload
          </Button>
        )}
      </Flex>
    </GridItem>
  );
};
