import React from 'react';
import { Box, Flex, Heading, Stack } from '@chakra-ui/react';
import { Logo } from '../Logo/components/Logo';
import styles from './Authentication.module.css';

export const Authentication: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <Flex height="100vh" width="100vw" className={styles.background}>
      <Flex padding={10} width="100%" flexDirection="column" alignItems="center">
        <Box w="lg" p={10} pt={8} color="gray.500" bg="white">
          <Stack spacing={10}>
            <Heading color="purple.500" as="h1" size="4xl">
              <Logo />
            </Heading>
            <Flex direction="column" gap={4}>
              {children}
            </Flex>
          </Stack>
        </Box>
      </Flex>
    </Flex>
  );
};
