import {
  Button,
  Flex,
  FormControl,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  useToast,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Authentication } from './Authentication';
import { authService } from './api';

export const ResetPassword: React.FC = () => {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  const handleRestPassword = async () => {
    if (email.length === 0) {
      toast({
        title: 'Email field cannot be empty!',
        status: 'error',
        position: 'top-right',
        duration: 3210,
        isClosable: true,
      });
      return;
    }

    if (email.indexOf('@') === -1) {
      toast({
        title: 'You entered an invalid email address!',
        status: 'error',
        position: 'top-right',
        duration: 3210,
        isClosable: true,
      });

      return;
    }

    setIsLoading(true);

    try {
      await authService.resetPassword(email);
      toast({
        title: 'An email with reset password instructions was sent to your email address!',
        status: 'success',
        position: 'top-right',
        duration: 3210,
        isClosable: true,
      });
    } catch (error: any) {
      console.log(error);
      // user does not exist
      toast({
        title: 'This email does not exist!',
        status: 'error',
        position: 'top-right',
        duration: 3210,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Authentication>
      <Heading as="h2" color="gray.600" fontSize="lg">
        Reset Password
      </Heading>
      <Stack spacing={4}>
        <Flex direction="column" gap={4} mb={6}>
          <FormControl isRequired>
            <InputGroup>
              <InputLeftElement>
                <FontAwesomeIcon icon={['fal', 'envelope']} />
              </InputLeftElement>
              <Input
                id="email"
                placeholder="Email Address"
                focusBorderColor="purple.500"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </InputGroup>
          </FormControl>
        </Flex>

        <Button
          colorScheme="purple"
          type="submit"
          shadow="md"
          size="lg"
          isLoading={isLoading}
          width="100%"
          onClick={handleRestPassword}
        >
          Send Email
        </Button>
      </Stack>
    </Authentication>
  );
};
