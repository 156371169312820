import { Box, Button, Flex, Grid, GridItem, Heading, List, ListItem, Radio, RadioGroup } from '@chakra-ui/react';
import { Organisation, useStateMachine } from 'little-state-machine';
import React from 'react';
import { useController, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { OnboardingVideo } from './components/Video';
import updateOnboarding from './updateOnboarding';

interface FormValues {
  organisation: Organisation;
}

export const Step2: React.FC = () => {
  const navigate = useNavigate();
  const { control, handleSubmit } = useForm<FormValues>();
  const { actions, state } = useStateMachine({ updateOnboarding });
  const {
    field: { onChange, onBlur, value, ref, name },
  } = useController({ defaultValue: state.onboarding.organisation, control: control, name: 'organisation' });

  const onSubmit = (data: FormValues) => {
    actions.updateOnboarding(data);
    // we have to create a project here
    navigate('/onboarding/step-3');
  };

  const onBack = () => {
    navigate('/onboarding');
  };

  return (
    <Grid width="100%" height="100%" templateColumns="20% 1fr 1fr 20%" placeItems="center">
      <GridItem />
      <GridItem>
        <OnboardingVideo url="https://video-poll.fra1.digitaloceanspaces.com/video-poll-assets%2F2.mp4" />
      </GridItem>
      <GridItem width="100%">
        <Box height="100%" width="100%" as="form" onSubmit={handleSubmit(onSubmit)}>
          <Flex direction="column" minH="400px" justifyContent="space-between" width="100%">
            <Heading fontSize="md" mb={4}>
              Tell us about your organisation
            </Heading>

            <RadioGroup colorScheme="purple" name={name} ref={ref} onChange={onChange} value={value} onBlur={onBlur}>
              <List spacing={6}>
                <ListItem border="1px solid #CBD5E0" p={4} borderRadius="md">
                  <Radio value="student">Still a student</Radio>
                </ListItem>
                <ListItem border="1px solid #CBD5E0" p={4} borderRadius="md">
                  <Radio value="himself">It's only me</Radio>
                </ListItem>
                <ListItem border="1px solid #CBD5E0" p={4} borderRadius="md">
                  <Radio value="1-20">1-20 people</Radio>
                </ListItem>
                <ListItem border="1px solid #CBD5E0" p={4} borderRadius="md">
                  <Radio value="11-50">11-50 people</Radio>
                </ListItem>
                <ListItem border="1px solid #CBD5E0" p={4} borderRadius="md">
                  <Radio value="51+">51+ people</Radio>
                </ListItem>
              </List>
            </RadioGroup>

            <Flex ml="auto" mt={4}>
              <Button colorScheme="purple" variant="outline" onClick={onBack}>
                Back
              </Button>
              <Button ml={4} type="submit" width="fit-content" colorScheme="purple">
                Continue
              </Button>
            </Flex>
          </Flex>
        </Box>
      </GridItem>
      <GridItem />
    </Grid>
  );
};
