import { Box } from '@chakra-ui/react';
import React from 'react';

export const TermsOfService: React.FC = () => {
  return (
    <Box p={20}>
      <div>
        <p className="c9 c23">
          <span className="c1 c20"></span>
        </p>
      </div>
      <p className="c24">
        <span className="c18">VIDEO POLL</span>
        <span className="c1 c11">&nbsp;AB</span>
        <span className="c1 c6">
          <br />
          <br />
          TERMS AND CONDITIONS FOR{' '}
        </span>
        <span className="c6">VIDEO POLL</span>
        <span className="c1 c6">
          &nbsp;SERVICE (ver.
          2017:1)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </p>
      <p className="c7 c9">
        <span className="c1 c6"></span>
      </p>
      <ol className="c8 lst-kix_list_3-0 start" start={1}>
        <li className="c7 c4 li-bullet-0">
          <span className="c1 c6">Basic understanding</span>
        </li>
      </ol>
      <p className="c2">
        <span className="c1 c6">1.1</span>
      </p>
      <p className="c2">
        <span className="c1 c0">In these Terms and Conditions any reference to &ldquo;</span>
        <span className="c1 c6">User</span>
        <span className="c1 c0">&rdquo; or &ldquo;</span>
        <span className="c1 c6">you</span>
        <span className="c1 c0">&rdquo; shall mean you as a user of the Service (as defined below) (the &ldquo;</span>
        <span className="c1 c6">User</span>
        <span className="c1 c0">&rdquo;), which is provided by </span>
        <span className="c0">Expensia A</span>
        <span className="c1 c0">B, reg.no </span>
        <span className="c0">559061-0522</span>
        <span className="c1 c0">, (hereinafter referred to as &ldquo;</span>
        <span className="c6">Video Poll</span>
        <span className="c1 c0">&rdquo;, &ldquo;</span>
        <span className="c1 c6">us</span>
        <span className="c1 c0">&rdquo; or &ldquo;</span>
        <span className="c1 c6">we</span>
        <span className="c1 c0">&rdquo;).</span>
      </p>
      <p className="c2 c9">
        <span className="c1 c0"></span>
      </p>
      <p className="c2">
        <span className="c1 c6">1.2</span>
      </p>
      <p className="c2">
        <span className="c0">Video Poll AB</span>
        <span className="c1 c0">&nbsp;and User are hereinafter individually referred to as a &ldquo;</span>
        <span className="c1 c6">Party</span>
        <span className="c1 c0">&rdquo; and jointly as the &ldquo;</span>
        <span className="c1 c6">Parties</span>
        <span className="c1 c0">&rdquo;.</span>
      </p>
      <p className="c2 c9 c10">
        <span className="c1 c0"></span>
      </p>
      <p className="c2 c10">
        <span className="c6 c5">1.3</span>
      </p>
      <p className="c2 c12">
        <span className="c0 c5">These provisions (the &ldquo;</span>
        <span className="c6 c5">Terms</span>
        <span className="c0 c5">&rdquo;) regulate the Terms and Conditions of the use of </span>
        <span className="c0">Video polls</span>
        <span className="c0 c5">&acute;s </span>
        <span className="c0">poll</span>
        <span className="c0 c5">&nbsp;service (including any related service provided by </span>
        <span className="c0">Video poll</span>
        <span className="c0 c5">)</span>
        <span className="c0">. </span>
        <span className="c0 c5">These Terms form a legally binding agreement between User and </span>
        <span className="c0">Video poll</span>
        <span className="c0 c5">&nbsp;and stipulates the Parties&rsquo; respective rights and obligations.</span>
      </p>
      <p className="c2 c12 c9">
        <span className="c1 c0"></span>
      </p>
      <p className="c2 c12">
        <span className="c6 c5">1.4</span>
      </p>
      <p className="c2 c12">
        <span className="c0 c5">
          In order to obtain access to the Service the User must accept to be bound by these Terms. If you do not accept
          these Terms, you may not use the Service and{' '}
        </span>
        <span className="c0">Video Poll </span>
        <span className="c1 c0">&nbsp;reserves the right to block you from the Service.</span>
      </p>
      <p className="c2 c12 c9">
        <span className="c1 c0"></span>
      </p>
      <p className="c2 c12">
        <span className="c6 c5">1.5</span>
      </p>
      <p className="c2 c12">
        <span className="c0">Video poll</span>
        <span className="c0 c5">
          &nbsp;reserves the right to change or replace the Terms at any time, at its sole discretion. User is
          responsible to keep updated of any such changes. User will however be informed of any changes to the Terms in
          advance [by e-mail and by a notification in the Account (as defined below)]. The changed Terms will enter into
          force{' '}
        </span>
        <span className="c0">
          immediately, if the user don&#39;t want to accept the terms the user can delete the account at any time..
        </span>
      </p>
      <p className="c2 c9">
        <span className="c1 c0"></span>
      </p>
      <ol className="c8 lst-kix_list_3-0" start={2}>
        <li className="c2 c4 li-bullet-0">
          <span className="c6 c3">Commencement, term and termination of Service</span>
        </li>
      </ol>
      <p className="c2 c9">
        <span className="c1 c0"></span>
      </p>
      <p className="c2">
        <span className="c6 c5">2.1 </span>
      </p>
      <p className="c2">
        <span className="c0 c5">These Terms shall be valid when using </span>
        <span className="c0">the service</span>
        <span className="c0 c5">&nbsp;and shall apply until the User or </span>
        <span className="c0">Video poll</span>
        <span className="c1 c0">&nbsp;terminates the relationship as explained below.</span>
      </p>
      <p className="c2 c9">
        <span className="c1 c0"></span>
      </p>
      <p className="c2">
        <span className="c6 c5">2.2</span>
      </p>
      <p className="c2">
        <span className="c0 c5">You are entitled to cease using </span>
        <span className="c0">Video Poll</span>
        <span className="c0 c5">&nbsp;at any time by unsubscribing</span>
        <span className="c0">.</span>
        <span className="c0 c3">&nbsp;(as defined below)</span>
        <span className="c0 c5">&nbsp;or sending a request of termination to </span>
        <span className="c0">Video poll</span>
        <span className="c0 c5">&nbsp;by e-mail to </span>
        <span className="c0">bottomsup@poll.video</span>
        <span className="c0 c5">
          . We will thereafter erase your personal information from our systems and anonymize all existing data
        </span>
        <span className="c0">&nbsp;</span>
        <span className="c0 c5">in accordance with our [</span>
        <span className="c0 c5 c14">:</span>
        <span className="c0 c5 c21">&nbsp;</span>
        <span className="c0 c17">
          <a
            className="c13"
            href="https://www.google.com/url?q=https://docs.google.com/document/d/1ppZa5kbb4QZC0IJ3oxJwxQip2rxJ7aSxTEpNjjRauVc/edit&amp;sa=D&amp;source=editors&amp;ust=1679236490995332&amp;usg=AOvVaw3eZnIl6WQFPvEIPgsZ7NZf"
          >
            Terms of service
          </a>
        </span>
        <span className="c1 c0">].</span>
      </p>
      <p className="c2 c9">
        <span className="c1 c0"></span>
      </p>
      <p className="c2">
        <span className="c6 c5">2.3</span>
      </p>
      <p className="c2">
        <span className="c0 c5">
          According to the Swedish Distance and Off-Premises Contracts Act you as a consumer is always entitled to
          withdraw a registered Account within fourteen (14) days after the registration. A request to end the Account
          shall be sent to{' '}
        </span>
        <span className="c0">Video poll</span>
        <span className="c0 c5">, in accordance with Section 2.2 above. The Swedish Consumer Agency (</span>
        <span className="c0 c5 c16">Sw: Konsumentverket</span>
        <span className="c1 c0">
          ) provides specific information in this respect to consumers. If User wishes to practice its right to withdraw
          from a concluded agreement entered into with a Merchant (as defined below), User shall direct the request to
          the relevant Merchant.
        </span>
      </p>
      <p className="c2 c9">
        <span className="c1 c0"></span>
      </p>
      <p className="c2">
        <span className="c6 c5">2.4</span>
        <span className="c0 c5">
          <br />
        </span>
        <span className="c0">Video poll</span>
        <span className="c1 c0">
          &nbsp;reserves the right, at its sole discretion, to suspend or terminate the User&rsquo;s access to the
          Service without prior written notice, under the following circumstances:
        </span>
      </p>
      <ol className="c8 lst-kix_list_11-0 start" start={1}>
        <li className="c2 c4 li-bullet-0">
          <span className="c1 c0">in case we consider that you are abusing or misusing the Service;</span>
        </li>
        <li className="c2 c4 li-bullet-0">
          <span className="c1 c0">
            if you have not adhered to any or all of the provisions in these Terms, or if it appears that you do not
            intend to or are unable to comply with the Terms;
          </span>
        </li>
        <li className="c2 c4 li-bullet-0">
          <span className="c0 c5">if </span>
          <span className="c0">Video poll</span>
          <span className="c0 c5">
            &nbsp;is required to by law or if there is a regulatory or statutory change limiting{' '}
          </span>
          <span className="c0">Video poll</span>
          <span className="c1 c0">&acute;s ability to provide access to the Service;</span>
        </li>
        <li className="c2 c4 li-bullet-0">
          <span className="c0 c5">there is any event beyond </span>
          <span className="c0">Video poll</span>
          <span className="c1 c0">
            &acute;s reasonable control preventing from providing access to the Service, including, but not limited to,
            technical difficulties, capacity problems and communications failures; or
          </span>
        </li>
        <li className="c2 c4 li-bullet-0">
          <span className="c0 c5">if </span>
          <span className="c0">the service</span>
          <span className="c0 c5">&nbsp;is no longer commercially viable for </span>
          <span className="c0">Video poll</span>
          <span className="c1 c0">.</span>
        </li>
      </ol>
      <p className="c2 c9">
        <span className="c1 c0"></span>
      </p>
      <p className="c2">
        <span className="c6 c5">2.5</span>
      </p>
      <p className="c2">
        <span className="c0 c5">In the event of termination of the Terms&rsquo; Sections </span>
        <span className="c0 c3">7, 8, 9</span>
        <span className="c0 c5 c22">&nbsp;</span>
        <span className="c0 c3">and</span>
        <span className="c0 c5 c22">&nbsp;</span>
        <span className="c0 c3">15 </span>
        <span className="c1 c0">
          shall continue to be valid in accordance with what is stated therein after the termination of these Terms.
        </span>
      </p>
      <p className="c2 c9">
        <span className="c1 c0"></span>
      </p>
      <ol className="c8 lst-kix_list_3-0" start={3}>
        <li className="c2 c4 li-bullet-0">
          <span className="c6 c5">Registration of the Service</span>
        </li>
      </ol>
      <p className="c2 c9">
        <span className="c1 c0"></span>
      </p>
      <p className="c2">
        <span className="c6 c5">3.1</span>
      </p>
      <p className="c2">
        <span className="c0 c5">In order to use </span>
        <span className="c0">the service</span>
        <span className="c0 c5">&nbsp;you must</span>
        <span className="c0 c5">&nbsp;register a user account via </span>
        <span className="c0">www.poll.video</span>
        <span className="c0 c5">&nbsp;(the &ldquo;</span>
        <span className="c6 c5">Account</span>
        <span className="c0 c5">
          &rdquo;). Upon registration you certify that you are eighteen (18) years or older, or otherwise have your
          guardians&acute; consent to use the Service, and that there are no other obstacles to your acceptance of these
          Terms. Upon registration, and in order to gain access to{' '}
        </span>
        <span className="c0">the service</span>
        <span className="c0 c5">, you are required to provide the following personal information (&ldquo;</span>
        <span className="c6 c5">User Information</span>
        <span className="c1 c0">&rdquo;):</span>
      </p>
      <p className="c2 c9">
        <span className="c1 c0"></span>
      </p>
      <ol className="c8 lst-kix_list_12-0 start" start={1}>
        <li className="c2 c4 li-bullet-0">
          <span className="c1 c0">full name;</span>
        </li>
        <li className="c2 c4 li-bullet-0">
          <span className="c0">e-mail address</span>
        </li>
      </ol>
      <p className="c2 c9 c19">
        <span className="c1 c0"></span>
      </p>
      <p className="c2 c9">
        <span className="c1 c0"></span>
      </p>
      <p className="c2">
        <span className="c6 c5">3.2</span>
      </p>
      <p className="c2">
        <span className="c0 c5">
          Upon registration, in accordance with Section 3.1 above, you accept these Terms. You further accept and agree
          that you will only provide us with information that is accurate, correct and up to date. You further agree to
          immediately inform us of any changes of the above personal information.{' '}
        </span>
        <span className="c0">Video poll</span>
        <span className="c1 c0">
          &nbsp;reserves the right to deny or cancel your access to the Service in case any such information is found to
          be incorrect.
        </span>
      </p>
      <p className="c2 c9">
        <span className="c1 c0"></span>
      </p>
      <p className="c2">
        <span className="c6 c5">3.3</span>
      </p>
      <p className="c2">
        <span className="c0 c5">Upon registration and in order for us to provide the Service properly, you may </span>
        <span className="c0">
          need to add more information about you as a private person or company in the future. &nbsp;
        </span>
      </p>
      <p className="c2 c9">
        <span className="c1 c0"></span>
      </p>
      <p className="c2">
        <span className="c6 c5">3.4</span>
      </p>
      <p className="c2">
        <span className="c0 c5">
          [You may, in addition to Section 3.3 above, link your e-mail account to the Service, in order for us to{' '}
        </span>
        <span className="c0">create</span>
        <span className="c0 c5">
          &nbsp;the Account. By linking your e-mail account to the Account you agree and accept that{' '}
        </span>
        <span className="c0">Video poll will store this information as long as you are a user.</span>
      </p>
      <p className="c2 c9">
        <span className="c1 c0"></span>
      </p>
      <p className="c2">
        <span className="c5 c6">3.5</span>
      </p>
      <p className="c2">
        <span className="c0 c5">All </span>
        <span className="c0">poll </span>
        <span className="c0 c5">information, such as for example </span>
        <span className="c0">poll questions</span>
        <span className="c0 c5">, </span>
        <span className="c0">results</span>
        <span className="c0 c5">, </span>
        <span className="c0">video clips</span>
        <span className="c0 c5">
          &nbsp;etc. transferred to the Account, on the basis of your consent, shall remain your property. Upon
          registration however, you agree and accept that{' '}
        </span>
        <span className="c0">Video poll</span>
        <span className="c1 c0">
          &nbsp;retains a royalty free and time unlimited right to store and use all information in accordance with what
          is stated in these Terms.
        </span>
      </p>
      <p className="c2 c9">
        <span className="c1 c0"></span>
      </p>
      <ol className="c8 lst-kix_list_3-0" start={4}>
        <li className="c2 c4 li-bullet-0">
          <span className="c6 c5">The use of the Service</span>
        </li>
      </ol>
      <p className="c2 c9">
        <span className="c1 c0"></span>
      </p>
      <p className="c2">
        <span className="c6 c5">4.1</span>
      </p>
      <p className="c2">
        <span className="c0 c5">Subject to the User&rsquo;s compliance with these Terms, </span>
        <span className="c0">Video poll</span>
        <span className="c0 c5">&nbsp;grants the User a non-exclusive right to use</span>
        <span className="c0">&nbsp;the Service</span>
        <span className="c0 c5">
          &nbsp;in accordance with these Terms for as long as the Terms is in force (the &ldquo;
        </span>
        <span className="c6 c5">License</span>
        <span className="c0 c5">
          &rdquo;). User accepts and agrees to follow and adhere to the guidelines and regulations of the
        </span>
        <span className="c0 c3">&nbsp;Service that </span>
        <span className="c0">Video poll</span>
        <span className="c1 c0">
          , from time to time, may announce. User further agrees to only use the Service for purposes that are permitted
          both by the Terms and by any applicable law.
        </span>
      </p>
      <p className="c2 c9">
        <span className="c1 c0"></span>
      </p>
      <p className="c2">
        <span className="c6 c5">4.2</span>
      </p>
      <p className="c2">
        <span className="c0 c5">Except as expressly permitted by </span>
        <span className="c0">Video poll</span>
        <span className="c1 c0">&nbsp;or permitted by applicable law, the User may not:</span>
      </p>
      <ol className="c8 lst-kix_list_7-0 start" start={1}>
        <li className="c2 c4 li-bullet-0">
          <span className="c1 c0">
            remove any copyright, trademark or other proprietary notices from any portion of the Service;
          </span>
        </li>
        <li className="c2 c4 li-bullet-0">
          <span className="c1 c0">
            reproduce, modify, prepare derivative works based upon, distribute, Licence, lease, sell, resell, transfer,
            publicly display, publicly perform, transmit, stream, broadcast or otherwise exploit the Service to the
            public and/or to any third party;
          </span>
        </li>
        <li className="c2 c4 li-bullet-0">
          <span className="c1 c0">decompile, reverse engineer or disassemble the Service;</span>
        </li>
        <li className="c2 c4 li-bullet-0">
          <span className="c1 c0">link to, mirror or frame any portion of the Service;</span>
        </li>
        <li className="c2 c4 li-bullet-0">
          <span className="c1 c0">
            cause or launch any programs or scripts for the purpose of scraping, indexing, surveying, or otherwise data
            mining any portion of the Service or unduly burdening or hindering the operation and/or functionality of any
            aspect of the Service;
          </span>
        </li>
        <li className="c2 c4 li-bullet-0">
          <span className="c1 c0">
            attempt to gain unauthorized access to or impair any aspect of the Service or its related systems or
            networks; or
          </span>
        </li>
        <li className="c2 c4 li-bullet-0">
          <span className="c1 c0">
            use the Licence and/or the Service in conflict with any applicable law and/or legislation.
          </span>
        </li>
      </ol>
      <p className="c2 c9">
        <span className="c1 c0"></span>
      </p>
      <p className="c2">
        <span className="c6 c5">4.3</span>
      </p>
      <p className="c2">
        <span className="c0 c5">The User&rsquo;s right to use the </span>
        <span className="c0">License</span>
        <span className="c0 c5">&nbsp;only permits the User to </span>
        <span className="c0 c3">use the </span>
        <span className="c0">License</span>
        <span className="c0 c3">&nbsp;for its private or company purposes, not to conflict with these Terms. If </span>
        <span className="c0">Video poll</span>
        <span className="c0 c3">&nbsp;suspects that the User is using the </span>
        <span className="c0">License</span>
        <span className="c0 c3">&nbsp;in a way that conflicts with these Terms or in a way that </span>
        <span className="c0">Video poll</span>
        <span className="c0 c3">&nbsp;considers as a non-normal use of the Licence, </span>
        <span className="c0">Video poll</span>
        <span className="c1 c0">
          &nbsp;can limit the Users by limiting the Licence and/or act in accordance with Section 2 above.
        </span>
      </p>
      <p className="c2 c9">
        <span className="c1 c0"></span>
      </p>
      <p className="c2">
        <span className="c6 c3">4.4</span>
      </p>
      <p className="c2">
        <span className="c0 c3">
          You agree to be responsible for keeping your passwords associated with your Account secret and secure.{' '}
        </span>
        <span className="c0">Video poll</span>
        <span className="c0 c3">
          &nbsp;may be able to help you retrieve lost passwords, but may not be held responsible or liable if it cannot
          help locate or reset a lost password. You agree that you will be solely responsible for all activities that
          occur under your account, whether you are aware of them or not. If you become aware of unauthorized use of
          your password or account, you agree to notify{' '}
        </span>
        <span className="c0">Video poll</span>
        <span className="c0 c3">&nbsp;via e-mail to </span>
        <span className="c0">hello@poll.video</span>
        <span className="c1 c0">&nbsp;as soon as possible.</span>
      </p>
      <p className="c2 c9">
        <span className="c1 c0"></span>
      </p>
      <ol className="c8 lst-kix_list_3-0" start={5}>
        <li className="c2 c4 li-bullet-0">
          <span className="c6 c3">Fees</span>
        </li>
      </ol>
      <p className="c2 c9">
        <span className="c1 c0"></span>
      </p>
      <p className="c2">
        <span className="c0 c3">The use of the Service is free of charge using the package.</span>
        <span className="c0">However, do you wish more functionality we offer paid packages as well.</span>
        <span className="c0 c3">
          . Fees to your mobile operator, internet supplier are charged separately without any involvement by{' '}
        </span>
        <span className="c0">Video poll</span>
        <span className="c1 c0">.</span>
      </p>
      <p className="c2 c9">
        <span className="c1 c0"></span>
      </p>
      <ol className="c8 lst-kix_list_3-0" start={6}>
        <li className="c2 c4 li-bullet-0">
          <span className="c6 c3">Privacy Policy</span>
        </li>
      </ol>
      <p className="c2 c9">
        <span className="c1 c0"></span>
      </p>
      <p className="c2">
        <span className="c0 c3">
          When accessing the Service the Users will be asked to enter personal information. The information entered can
          include, but might not be limited to the User Information. (&ldquo;
        </span>
        <span className="c6 c3">Personal Data</span>
        <span className="c0 c3">&rdquo;). </span>
        <span className="c0">Video poll</span>
        <span className="c0 c3">&nbsp;handles Personal Data and </span>
        <span className="c0">is there for a </span>
        <span className="c0 c3">
          Data Controller. When entering any Personal Data, the User gives his/her consent to{' '}
        </span>
        <span className="c0">Video poll</span>
        <span className="c1 c0">&nbsp;to handle and use Personal Data.</span>
      </p>
      <p className="c2 c9">
        <span className="c1 c0"></span>
      </p>
      <ol className="c8 lst-kix_list_3-0" start={7}>
        <li className="c2 c4 li-bullet-0">
          <span className="c6 c5">Intellectual property rights </span>
        </li>
      </ol>
      <p className="c2 c9">
        <span className="c1 c0"></span>
      </p>
      <p className="c2">
        <span className="c6 c5">7.1</span>
      </p>
      <p className="c2">
        <span className="c0 c5">No rights to any intellectual property are granted to </span>
        <span className="c0">Video poll</span>
        <span className="c0 c5">&nbsp;under these Terms. You acknowledge that all legal </span>
        <span className="c0">rights are</span>
        <span className="c0 c5">&nbsp;the exclusive property of </span>
        <span className="c0">the user.</span>
      </p>
      <p className="c2 c9">
        <span className="c1 c0"></span>
      </p>
      <p className="c2">
        <span className="c6 c5">7.2</span>
      </p>
      <p className="c2">
        <span className="c0 c5">
          The User also undertakes not to use, modify, disseminate, sell or save information provided by{' '}
        </span>
        <span className="c0">Video poll</span>
        <span className="c0 c5">
          &nbsp;and not to publish or indirectly cause the publication of articles, survey results or other material
          produced by{' '}
        </span>
        <span className="c0">Video poll</span>
        <span className="c1 c0">.</span>
      </p>
      <p className="c2 c9">
        <span className="c1 c0"></span>
      </p>
      <p className="c2">
        <span className="c6 c5">7.3</span>
      </p>
      <p className="c2">
        <span className="c0 c5">All information as presented by </span>
        <span className="c0">the service</span>
        <span className="c0 c5">&nbsp;under the Terms is </span>
        <span className="c0">Video poll</span>
        <span className="c0 c5">
          &rsquo;s exclusive property. In no event shall User sell, transfer or market any information, results or
          findings presented by{' '}
        </span>
        <span className="c0">Video poll</span>
        <span className="c1 c0">&nbsp;under these Terms to a third party.</span>
      </p>
      <ol className="c8 lst-kix_list_3-0" start={8}>
        <li className="c7 c4 li-bullet-0">
          <span className="c1 c6">Access and Disclaimer</span>
        </li>
      </ol>
      <p className="c2">
        <span className="c6 c5">8.1</span>
      </p>
      <p className="c2">
        <span className="c0">Video poll</span>
        <span className="c0 c5">
          &nbsp;strives to ensure that the Service is available twenty-four (24) hours a day. However,{' '}
        </span>
        <span className="c0">Video poll</span>
        <span className="c1 c0">
          &nbsp;shall not be liable if, for any reason, the Service is unavailable at any time or for any period.
        </span>
      </p>
      <p className="c2 c9">
        <span className="c1 c0"></span>
      </p>
      <p className="c2">
        <span className="c6 c5">8.2</span>
      </p>
      <p className="c2">
        <span className="c0 c5">
          Access to the Services may be suspended temporarily and without notice in the case of a system failure,
          maintenance or repair or for any reasons beyond{' '}
        </span>
        <span className="c0">Video poll</span>
        <span className="c0 c5">&rsquo;s control. </span>
        <span className="c0">Video poll</span>
        <span className="c0 c5">
          &nbsp;shall in no event be liable for any User Information that the User or any other party is publishing in
          the Service.{' '}
        </span>
        <span className="c0">Video poll</span>
        <span className="c0 c5">
          &nbsp;shall not be responsible if any User Information, published by User or other, is deleted and the User
          therefore acknowledges that it is responsible to continuously keep a backup of such User Information.
        </span>
      </p>
      <p className="c2 c9">
        <span className="c1 c0"></span>
      </p>
      <p className="c2 c9">
        <span className="c1 c0"></span>
      </p>
      <p className="c2">
        <span className="c6 c5">8.4</span>
      </p>
      <p className="c2">
        <span className="c0 c5">
          It is your responsibility to inform yourself about how the Service&rsquo;s different features works before
          registration, as stipulated in Section 3 above. You acknowledge, agree and understand that you use the Service
          at your own risk and that the Service is provided by{' '}
        </span>
        <span className="c0">Video poll</span>
        <span className="c0 c5">
          &nbsp;on an &ldquo;as is&rdquo; basis and &ldquo;as available&rdquo; without any warranties of any kind,
          either expressed or implied.{' '}
        </span>
        <span className="c0">Video poll</span>
        <span className="c0 c5">
          , to the fullest extent permitted by law, disclaims all warranties, including, but not limited to, the
          warranties of merchantability, title, non-infringement of third parties&rsquo; rights, and fitness for
          particular purpose.{' '}
        </span>
        <span className="c0">Video poll</span>
        <span className="c1 c0">
          &nbsp;makes no warranties about the accuracy, reliability, completeness, or timeliness of the Service.
        </span>
      </p>
      <ol className="c8 lst-kix_list_3-0" start={9}>
        <li className="c7 c4 li-bullet-0">
          <span className="c1 c6">Limitation of liability</span>
        </li>
      </ol>
      <p className="c2">
        <span className="c6 c5">9.1</span>
      </p>
      <p className="c2">
        <span className="c1 c0">
          The User assumes all responsibility and risk for its use of the Service, the internet generally, and the
          documents that are posted or accessed in or relating to the Service and for the User&acute;s conduct on and
          off site.
        </span>
      </p>
      <p className="c2 c9">
        <span className="c1 c0"></span>
      </p>
      <p className="c2">
        <span className="c6 c5">9.2</span>
      </p>
      <p className="c2">
        <span className="c0 c5">In no event shall </span>
        <span className="c0">Video poll</span>
        <span className="c0 c5">
          &nbsp;(or any of its officers, directors, shareholders, employees, agents or advertisers) be liable for any
          non-direct damages whatsoever (including, without limitation, incidental and consequential damages, lost
          profits, or damages resulting from lost data, lost employment opportunity, or business interruption) resulting
          from or arising under or in connection with the use or access to, or the inability to use or access, the
          Service and/or any document, whether based on warranty, contract, tort, or any other legal theory and whether
          or not{' '}
        </span>
        <span className="c0">Video poll</span>
        <span className="c1 c0">&nbsp;is advised of the possibility of such damages.</span>
      </p>
      <p className="c2 c9">
        <span className="c1 c0"></span>
      </p>
      <p className="c2">
        <span className="c6 c5">9.3</span>
      </p>
      <p className="c2">
        <span className="c1 c0">
          The limitation of liability in this Section 9 shall not affect the statutory rights that a consumer is
          entitled to, in accordance with Swedish law, that cannot be changed or suspended by agreement.
        </span>
      </p>
      <ol className="c8 lst-kix_list_3-0" start={10}>
        <li className="c7 c4 li-bullet-0">
          <span className="c1 c6">Other content</span>
        </li>
      </ol>
      <p className="c7">
        <span className="c1 c0">The Service may include hyperlinks to other </span>
        <span className="c0">websites</span>
        <span className="c1 c0">, content or other resources. </span>
        <span className="c0">Video poll</span>
        <span className="c1 c0">
          &nbsp;does not necessarily endorse any advertising, products or other materials featured or available on other
          web sites or resources.
        </span>
      </p>
      <ol className="c8 lst-kix_list_3-0" start={11}>
        <li className="c4 c7 li-bullet-0">
          <span className="c1 c6">Changes and amendments to these Terms</span>
        </li>
      </ol>
      <p className="c7">
        <span className="c1 c6">11.1</span>
        <span className="c1 c0">
          <br />
        </span>
        <span className="c0">Video poll</span>
        <span className="c1 c0">
          &nbsp;retains the right to change at any time the structure of the Service as well as these Terms. Changes to
          the Terms come into force thirty (30) days after the amended Terms have been sent to the User.
        </span>
      </p>
      <p className="c7">
        <span className="c1 c6">11.2</span>
        <span className="c1 c0">
          <br />
          The User may not assign or transfer the obligations (in whole or in part) under these Terms. No waiver of any
          term of these Terms shall be deemed a further or continuing waiver of such term or any other term.
        </span>
      </p>
      <p className="c7">
        <span className="c1 c6">11.3</span>
        <span className="c1 c0">
          <br />
          If any provision of these Terms are found to be invalid by any court having competent jurisdiction, the
          invalidity of all or part of a provision shall not affect the validity of the remaining parts and provisions
          of these Terms, which shall remain in full force and effect.
        </span>
      </p>
      <ol className="c8 lst-kix_list_3-0" start={12}>
        <li className="c2 c4 li-bullet-0">
          <span className="c6 c3">Communication and Support</span>
        </li>
      </ol>
      <p className="c2 c9">
        <span className="c1 c0"></span>
      </p>
      <p className="c2">
        <span className="c6 c3">12.1</span>
      </p>
      <p className="c2">
        <span className="c0 c3">
          Should the User wish to receive support regarding the use of the Service or these Terms, the User may contact{' '}
        </span>
        <span className="c0">Video poll</span>
        <span className="c0 c3">&nbsp;at </span>
        <span className="c0 c16">hello@poll.video</span>
        <span className="c0 c3">, </span>
      </p>
      <p className="c2 c9">
        <span className="c1 c0"></span>
      </p>
      <p className="c2">
        <span className="c6 c3">12.2</span>
      </p>
      <p className="c2">
        <span className="c0 c3">
          Messages from us are sent either via e-mail and/or notification in your personal Account. We may also provide
          general information
        </span>
        <span className="c0">&nbsp;</span>
        <span className="c0 c3">in the </span>
        <span className="c0">Service.</span>
        <span className="c1 c0">
          . We use the contact information you provide us with in connection with registration. &nbsp;
        </span>
      </p>
      <p className="c2 c9">
        <span className="c1 c0"></span>
      </p>
      <ol className="c8 lst-kix_list_3-0" start={13}>
        <li className="c2 c4 li-bullet-0">
          <span className="c6 c5">Returns, Claims, etc. </span>
        </li>
      </ol>
      <p className="c2">
        <span className="c0 c5">
          Should you want to return, exchange or report a claim regarding a product or a service that you have purchased
          from a Merchant, you will direct your request or claim directly to{' '}
        </span>
        <span className="c0">Video Poll</span>
        <span className="c1 c0">.</span>
      </p>
      <p className="c2 c9">
        <span className="c1 c0"></span>
      </p>
      <ol className="c8 lst-kix_list_3-0" start={14}>
        <li className="c2 c4 li-bullet-0">
          <span className="c6 c5">Miscellaneous </span>
        </li>
      </ol>
      <p className="c2 c9">
        <span className="c1 c0"></span>
      </p>
      <p className="c2">
        <span className="c1 c0">
          These Terms and all exhibits and schedules hereto, constitute the entire understanding between the Parties and
          supersede all prior oral or written agreements between the Parties relating to the subject matter contained
          herein, and merges all prior and contemporaneous discussions among them.
        </span>
      </p>
      <p className="c2 c9">
        <span className="c1 c0"></span>
      </p>
      <ol className="c8 lst-kix_list_3-0" start={15}>
        <li className="c2 c4 li-bullet-0">
          <span className="c6 c3">Governing law and disputes</span>
        </li>
      </ol>
      <p className="c2 c9">
        <span className="c1 c0"></span>
      </p>
      <p className="c2">
        <span className="c6 c3">15.1</span>
        <span className="c1 c0">
          <br />
          These Terms shall be governed by, and constructed in accordance with, Swedish material law.
        </span>
      </p>
      <p className="c2 c9">
        <span className="c1 c0"></span>
      </p>
      <p className="c2">
        <span className="c6 c3">15.2</span>
        <span className="c0 c3">
          <br />
          The Parties shall endeavor to settle any dispute, controversy or claim arising out of or in connection with
          these Terms and the use of the Service by good faith. Should the negotiations fail, the dispute may be finally
          settled by the National Board for Consumer Disputes (
        </span>
        <span className="c0 c3 c16">Sw: Allm&auml;nna Reklamationsn&auml;mnden</span>
        <span className="c1 c0">
          ), insofar as it is competent to try the matter of the dispute. Otherwise, the dispute shall be finally
          settled by the common courts of Sweden.
        </span>
      </p>
      <p className="c2 c9">
        <span className="c1 c0"></span>
      </p>
      <p className="c2 c9">
        <span className="c1 c0"></span>
      </p>
      <p className="c2">
        <span className="c0 c3">Last update of these Terms, </span>
        <span className="c0">January</span>
        <span className="c0 c3">&nbsp;20</span>
        <span className="c0">23</span>
      </p>
      <div>
        <p className="c23 c9">
          <span className="c1 c20"></span>
        </p>
      </div>
    </Box>
  );
};
