import { Box, Checkbox, Radio, RadioGroup, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';

// TODO: Fix interfaces
interface Answer {
  [key: string]: any;
}
interface Poll {
  [key: string]: any;
}
interface Question {
  [key: string]: any;
}

type QuestionMeAnswers = Question & { poll: Poll; answers: Answer[] };
const SingleAnswer: React.FC<{ qIndex: number; defaultValue?: QuestionMeAnswers }> = ({ qIndex, defaultValue }) => {
  const { control } = useFormContext();
  const { fields } = useFieldArray({
    control,
    name: `questions`,
  });

  return (
    <Stack mt={20} spacing={4}>
      <Controller
        name={`questions.${qIndex}.answers`}
        control={control}
        defaultValue={defaultValue?.answers}
        render={({ field }) => {
          return (
            <RadioGroup {...field} colorScheme="purple">
              <Stack>
                {(fields[qIndex] as any)?.answers?.map((answer: any, i: number) => (
                  <Radio key={answer.id} value={answer.id} name={`questions.${qIndex}.answers.${i}`}>
                    {answer.text}
                  </Radio>
                ))}
              </Stack>
            </RadioGroup>
          );
        }}
      />
      )
    </Stack>
  );
};
const MultipleAnswers: React.FC<{ qIndex: number; defaultValue?: QuestionMeAnswers }> = ({ qIndex }) => {
  const { control, register } = useFormContext();
  const { fields } = useFieldArray({
    control,
    name: `questions`,
  });

  return (
    <Stack mt={20} spacing={4}>
      {(fields[qIndex] as any)?.answers?.map((answer: any) => {
        return (
          <Checkbox
            key={answer.id}
            colorScheme="purple"
            id={`questions.${qIndex}.answers.${answer.id}`}
            {...register(`questions.${qIndex}.answers.${answer.id}` as const)}
            defaultValue={answer.id}
          >
            {answer.text}
          </Checkbox>
        );
      })}
    </Stack>
  );
};
export const QuestionStep: React.FC<{ qIndex: number; question: QuestionMeAnswers }> = ({ qIndex, question }) => {
  const isMultiple = question?.type === 'Multiple';
  // TODO: handle show the comment box
  // but how should we do it
  // should we first show the answer options and after you pick you get the
  // comment input ?
  // const canUsersComment = question?.canUsersComment;

  return (
    <Box mt={20} mb={20}>
      <Text maxWidth="800px" fontSize="xl" fontWeight="bold">
        {question?.text}
      </Text>
      {isMultiple ? (
        <Text fontSize="sm" mt={1} color="gray.400">
          You need to select at least {question.minNumberOfAlternatives} and at most {question.maxNumberOfAlternatives}{' '}
          answers!
        </Text>
      ) : null}

      {isMultiple ? (
        <MultipleAnswers qIndex={qIndex} defaultValue={question} />
      ) : (
        <SingleAnswer qIndex={qIndex} defaultValue={question} />
      )}

      {/* {canUsersComment && <span>here will go the user commment</span>} */}
    </Box>
  );
};
