import { Box, Flex, Image } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { fetchCurrentUser } from './profile.service';

export const Profile: React.FC = () => {
  const { data: user } = useQuery(['profile'], () => fetchCurrentUser());
  return (
    <Flex width="100%" alignItems="center" justifyContent="center">
      <Box maxW="lg" borderWidth="1px" borderRadius="lg" overflow="hidden">
        <Image src={user?.profilePictureUrl} alt={user?.fullName} boxSize="500px" />
        <Box p="6">
          <Box display="flex" alignItems="baseline">
            <Box color="gray.500" fontWeight="semibold" letterSpacing="wide" fontSize="xs" textTransform="uppercase">
              {user?.fullName}
            </Box>
          </Box>
          <Box mt="1" fontWeight="semibold" as="h4" lineHeight="tight">
            {user?.email}
          </Box>
        </Box>
      </Box>
    </Flex>
  );
};
