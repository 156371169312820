import { Flex } from '@chakra-ui/react';
import isEmpty from 'lodash-es/isEmpty';
import * as React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { authService } from '../Authentication/api';
import { useAuth } from '../Authentication/ProvideAuth';
import { ErrorBoundary } from '../ErrorBoundary';
import { Header } from '../Header';

export const AppContainer: React.FC = () => {
  const auth = useAuth();
  const isAuthenticated = authService.isAuthenticated() || !isEmpty(auth.user);
  return isAuthenticated ? (
    <React.Suspense fallback={null}>
      <ErrorBoundary>
        <Header />
        <Flex as="main" pt="4rem" width="100vw" height="100vh">
          <Outlet />
        </Flex>
      </ErrorBoundary>
    </React.Suspense>
  ) : (
    <Outlet />
  );
};

export const ProtectedRoute: React.FC<React.PropsWithChildren<any>> = ({ children }) => {
  const auth = useAuth();
  const isAuthenticated = authService.isAuthenticated() || !isEmpty(auth.user);

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return children;
};
