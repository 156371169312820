import React from 'react';
import { GridItem, Flex, Button, Image, Text } from '@chakra-ui/react';
import { Logo } from '../../Logo/components/Logo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Outro } from '../../types';

interface Props {
  outros: Outro[];
  shouldShowResults: boolean;
}
export const OutroView: React.FC<Props> = ({ outros, shouldShowResults }) => {
  return (
    <GridItem p={8} colSpan={2} placeItems="center">
      <Flex mt={15} justifyContent="center" alignItems="center" direction="column" p={10}>
        {outros[0]?.url && <Image src={outros[0]?.url} alt="Outro Gif" minHeight="500px" />}
        {shouldShowResults && (
          <Button
            as="a"
            href={`results`}
            mt={6}
            colorScheme="blackAlpha"
            background="black"
            color="white"
            rightIcon={<FontAwesomeIcon icon={['fal', 'chart-bar']} />}
          >
            See Results
          </Button>
        )}

        <Flex mt={20} direction="column" alignItems="center" justifyContent="center">
          <Text fontWeight="bold">Created with</Text>
          <Logo />
          <Text>www.poll.video</Text>
        </Flex>
      </Flex>
    </GridItem>
  );
};
