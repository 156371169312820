import { useState, useEffect } from 'react';
import useLocalStorage from 'beautiful-react-hooks/useLocalStorage';
import { User } from '../types';
import { authService, StorageKeys } from './api';

type Auth = {
  fullName?: string;
  email: string;
  password: string;
};
export const useProvideAuth = () => {
  const [user, setUser] = useState<any>(null);
  const [currentUser] = useLocalStorage<User | null>(StorageKeys.CurrentUser, null);
  const isAuthenticated = authService.isAuthenticated();

  useEffect(() => {
    if (user) {
      return;
    }

    if (isAuthenticated) {
      setUser(currentUser);
    }
  }, [user, currentUser, isAuthenticated]);

  const login = async ({ email, password }: Auth) => {
    const session = await authService.login({ email, password });
    setUser(session?.user);
    return Promise.resolve(user);
  };

  const signup = async ({ fullName, email, password }: Auth) => {
    const session = await authService.signup({ fullName, email, password });
    setUser(session?.user);

    return Promise.resolve(user);
  };

  const logout = () => authService.logout().then(() => setUser(null));

  return {
    user,
    login,
    signup,
    logout,
    setUser,
  };
};
