import { Box, Button, Flex, Grid, GridItem, Heading, List, ListItem, Radio, RadioGroup } from '@chakra-ui/react';
import { Experience, useStateMachine } from 'little-state-machine';
import React from 'react';
import { useController, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { OnboardingVideo } from './components/Video';
import updateOnboarding from './updateOnboarding';
interface FormValues {
  experience: Experience;
}

export const Step1: React.FC = () => {
  const navigate = useNavigate();
  const { control, handleSubmit } = useForm<FormValues>();
  const { actions, state } = useStateMachine({ updateOnboarding });
  const {
    field: { onChange, onBlur, value, ref, name },
  } = useController({ defaultValue: state.onboarding.experience, control: control, name: 'experience' });

  const onSubmit = (data: FormValues) => {
    actions.updateOnboarding(data);
    navigate('/onboarding/step-2');
  };

  return (
    <Grid width="100%" height="100%" templateColumns="20% 1fr 1fr 20%" placeItems="center">
      <GridItem />
      <GridItem>
        <OnboardingVideo url="https://video-poll.fra1.digitaloceanspaces.com/video-poll-assets%2F1.mp4" />
      </GridItem>
      <GridItem width="100%">
        <Box as="form" onSubmit={handleSubmit(onSubmit)} height="100%" width="100%">
          <Flex direction="column" minH="400px" justifyContent="space-between" width="100%">
            <Heading fontSize="md" mb={4}>
              Your experience in being in front of camera?
            </Heading>

            <RadioGroup colorScheme="purple" name={name} ref={ref} onChange={onChange} value={value} onBlur={onBlur}>
              <List spacing={6}>
                <ListItem border="1px solid #CBD5E0" p={4} borderRadius="md">
                  <Radio value="star" border="1px solid tomato">
                    I am a TV-star!
                  </Radio>
                </ListItem>
                <ListItem border="1px solid #CBD5E0" p={4} borderRadius="md">
                  <Radio value="fine">Nowadays I feel totally fine</Radio>
                </ListItem>
                <ListItem border="1px solid #CBD5E0" p={4} borderRadius="md">
                  <Radio value="scary">To be honest a bit scary</Radio>
                </ListItem>
                <ListItem border="1px solid #CBD5E0" p={4} borderRadius="md">
                  <Radio value="worse">Nothing is worse</Radio>
                </ListItem>
              </List>
            </RadioGroup>

            <Button type="submit" width="fit-content" colorScheme="purple" ml="auto">
              Continue
            </Button>
          </Flex>
        </Box>
      </GridItem>
      <GridItem />
    </Grid>
  );
};
