import React from 'react';
import { createBrowserRouter, Navigate, Outlet } from 'react-router-dom';
import { LoginPage } from '../Authentication/LoginPage';
import { SignupPage } from '../Authentication/SignupPage';
import { Onboarding } from '../Onboarding/Onboarding';
import { PollViewer } from '../PollViewer/components/PollViewer';
import { Profile } from '../Profile/Profile';
import ProjectsContainer, { projectsLoader } from '../Projects/components/ProjectsContainer';
import { PrivacyPolicy } from '../PublicPages/PrivacyPolicy';
import { TermsOfService } from '../PublicPages/TermsOfService';
import { queryClient } from '../utils';
import { ErrorElement } from './ErrorElement';
import { NotFoundElement } from './NotFoundElement';
import { AppContainer, ProtectedRoute } from './ProtectedRoute';
import { VerifyEmail } from '../PublicPages/VerifyEmail';
import { ResetPassword } from '../Authentication/ResetPassword';
import { ResetPasswordConfirm } from '../Authentication/ResetPasswordConfirm';

const Poll = React.lazy(() => import('../Polls/components/Poll'));

export const router = createBrowserRouter([
  {
    path: '/',
    element: <Outlet />,
    errorElement: <ErrorElement />,
    children: [
      /* ---------- PUBLIC ROUTES ----------- */
      {
        path: '/login',
        element: <LoginPage />,
      },
      {
        path: '/signup',
        element: <SignupPage />,
      },
      {
        path: '/password-reset',
        element: <ResetPassword />,
      },
      {
        path: '/password-reset/confirm/:token',
        element: <ResetPasswordConfirm />,
      },
      {
        path: '/view/:pollId/*',
        element: <PollViewer />,
      },
      {
        path: '/terms-of-service',
        element: <TermsOfService />,
      },
      {
        path: '/privacy-policy',
        element: <PrivacyPolicy />,
      },
      {
        path: '/verify-email/:token',
        element: <VerifyEmail />,
      },
      {
        /* ---------- PRIVATE ROUTES ----------- */
        element: (
          <ProtectedRoute>
            <AppContainer />
          </ProtectedRoute>
        ),
        children: [
          {
            index: true,
            element: <Navigate to="/projects" replace={true} />,
          },
          {
            path: '/onboarding/*',
            element: <Onboarding />,
          },
          {
            path: '/projects/*',
            loader: projectsLoader(queryClient),
            element: <ProjectsContainer />,
          },
          {
            path: '/polls/:pollId/*',
            element: <Poll />,
          },
          {
            path: '/profile/*',
            element: <Profile />,
          },
        ],
      },
      {
        path: '*',
        element: <NotFoundElement />,
      },
    ],
  },
]);
