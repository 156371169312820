import { UserSession } from '../../types';
import { ApiEndpoints } from './ApiEndpoints';
import { authHttp } from './auth.http';
import { http } from './http';

const { SignupUrl, AuthenticateUrl, LogoutUrl, AuthenticateGoogleUrl } = ApiEndpoints;

export enum StorageKeys {
  CurrentUser = 'videoPoll:currentUser',
  AccessToken = 'videoPoll:accessToken',
  ExpiresAt = 'videoPoll:expiresAt',
}

interface AuthSession {
  fullName?: string;
  email: string;
  password: string;
}
class AuthService {
  private logoutTimeout: number | undefined;

  constructor() {
    this.logoutTimeout = this.scheduleLogout(this.getExpiresAt());
  }

  public isAuthenticated(): boolean {
    return this.tokenNotExpired() && this.getCurrentUser() != null;
  }

  public async login({ email, password }: AuthSession): Promise<UserSession> {
    try {
      const { data } = await authHttp.post(AuthenticateUrl, { email, password });
      return this.setSession(data);
    } catch (error) {
      throw error;
    }
  }

  public async loginGoogle({ token }: { token?: string }): Promise<UserSession> {
    try {
      const { data } = await http.post(AuthenticateGoogleUrl, { token });
      return this.setSession(data);
    } catch (error) {
      throw error;
    }
  }

  public async signup({ fullName, email, password }: AuthSession): Promise<UserSession> {
    try {
      const { data } = await authHttp.post(SignupUrl, { fullName, email, password });
      return this.setSession(data);
    } catch (error) {
      throw error;
    }
  }

  public logout() {
    const body = { token: this.getToken() };

    const redirect = () => {
      this.cancelTimeout();
      localStorage.clear();
      window.location.replace('/login');
    };

    return authHttp
      .post(LogoutUrl, body)
      .then(() => redirect())
      .catch(() => redirect());
  }

  public resetPassword(email: string) {
    return authHttp.post('/auth/reset-password', { email }).then((response: any) => response);
  }

  public confirmResetPassword(token: string, password: string, confirmPassword: string) {
    return authHttp
      .post('/auth/verify-reset-password', { token, password, confirmPassword })
      .then((response: any) => response);
  }

  /**
   * Current authenticated user object
   */
  getCurrentUser(): Record<string, string> | null {
    try {
      const currentUser = localStorage.getItem(StorageKeys.CurrentUser);
      return currentUser ? JSON.parse(currentUser) : null;
    } catch (error) {
      return null;
    }
  }

  getToken(): string | null {
    return localStorage.getItem(StorageKeys.AccessToken);
  }

  /**
   * Save JWT token in local storage
   */
  private setSession(session: any): Promise<UserSession> {
    const { accessToken, expiresIn, user } = session;

    const expiresAt = this.absolutizeExpirationTime(expiresIn);
    this.logoutTimeout = this.scheduleLogout(expiresAt, accessToken);

    try {
      if (accessToken && user && expiresAt) {
        localStorage.setItem(StorageKeys.AccessToken, accessToken);
        localStorage.setItem(StorageKeys.CurrentUser, JSON.stringify(user));
        localStorage.setItem(StorageKeys.ExpiresAt, JSON.stringify(expiresAt));
        return Promise.resolve({ accessToken, expiresAt, user });
      }
      return Promise.reject('Failed to authenticate user!');
    } catch (error) {
      return Promise.reject(error);
    }
  }

  private tokenNotExpired(): boolean {
    try {
      const expiresAt = this.getExpiresAt();
      const now = new Date().getTime();
      const offset = this.tokenRefreshOffset();

      if (expiresAt && expiresAt > now - offset) {
        return true;
      }

      if (this.getToken()) {
        this.cancelTimeout();
        localStorage.clear();
        window.location.replace('/login');
      }
      return false;
    } catch (_) {
      if (this.getToken()) {
        this.cancelTimeout();
        localStorage.clear();
        window.location.replace('/login');
        return false;
      }
      localStorage.clear();
      this.cancelTimeout();
      return false;
    }
  }

  private cancelTimeout() {
    window.clearTimeout(this.logoutTimeout);
    this.logoutTimeout = undefined;
  }

  private getExpiresAt(): number | null {
    try {
      const expiresAt = localStorage.getItem(StorageKeys.ExpiresAt);
      return expiresAt ? JSON.parse(expiresAt) : null;
    } catch (error) {
      return null;
    }
  }

  private scheduleLogout(expiresAt: number | null, token = this.getToken()): number | undefined {
    if (!token || !expiresAt) {
      return undefined;
    }

    const now = new Date().getTime();
    const offset = this.tokenRefreshOffset();

    if (expiresAt > now - offset) {
      window.clearTimeout(this.logoutTimeout);
      this.logoutTimeout = undefined;
      return window.setTimeout(() => {
        // TODO: refresh token
        this.logout();
      }, expiresAt - now - offset);
    }

    return undefined;
  }

  private tokenRefreshOffset() {
    const min = 5;
    const max = 10;

    return (Math.floor(Math.random() * (max - min)) + min) * 1000;
  }

  private absolutizeExpirationTime(expiresIn: string | undefined): number {
    return expiresIn ? new Date(new Date().getTime() + parseInt(expiresIn, 10) * 1000).getTime() : 0;
  }
}

export const authService = new AuthService();
