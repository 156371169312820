import { ApiEndpoints, http } from '../Authentication/api';
import { Project } from '../types';

const { PollViewUrl, ParticipantsUrl, PollResultsUrl } = ApiEndpoints;

// TODO: Fix interfaces
interface Participant {
  [key: string]: any;
}
interface PollWithRelation {
  [key: string]: any;
}
export type PollView = {
  poll: PollWithRelation;
  participant: Participant;
  project: Project;
};

type PollResponse = {
  data: PollView;
};

export const fetchPollView = async ({ pollId, password }: { pollId: string; password: string }): Promise<PollView> => {
  const { data } = await http
    .post<PollView, PollResponse>(`${PollViewUrl}/${pollId}`, { password } as any)
    .then((response: PollResponse) => response);
  return data;
};

// TODO: Fix types
export const fetchPollResults = async (pollId: string): Promise<any> => {
  const { data } = await http.get<any>(`${PollResultsUrl}/${pollId}`).then((response: any) => response);
  return data;
};

// TODO: Fix types
export const saveParticipantAnswers = async ({
  participant,
  answerIds,
}: {
  participant: Participant;
  answerIds: string[];
}): Promise<any> => {
  const { data } = await http
    .post<any>(`${ParticipantsUrl}/${participant.id}/answers`, { answerIds })
    .then((response: PollResponse) => response);
  return data;
};
