import {
  Button,
  Divider,
  Flex,
  FormControl,
  Heading,
  Input,
  List,
  ListIcon,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMutation, useQuery } from '@tanstack/react-query';
import React from 'react';
import { useForm } from 'react-hook-form';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { authService } from '../../Authentication/api';
import { makeProject } from '../projects.mode';
import { createProject } from '../projects.service';
import { projectsQuery } from './ProjectsContainer';

type Inputs = {
  name: string;
};

interface ModalProps {
  isOpen: boolean;
  isLoading: boolean;
  onCreateProject: (inputs: Inputs) => void;
  onClose: () => void;
}
const CreateNewProjectModal: React.FC<ModalProps> = ({ isOpen, onClose, onCreateProject, isLoading }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({ defaultValues: { name: '' } });

  return (
    <Modal isOpen={isOpen} onClose={onClose} autoFocus size="full">
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={handleSubmit(onCreateProject)}>
          <ModalCloseButton />
          <ModalBody>
            <Flex direction="column" alignItems="center" justifyContent="center" gap="6" height="50vh">
              <Stack spacing={4} width="400px">
                <Heading>New Project</Heading>
                <FormControl>
                  <Input
                    {...register('name', { required: true })}
                    id="name"
                    autoFocus
                    isInvalid={errors.name != null}
                    placeholder="Name your project"
                    focusBorderColor="purple.500"
                    type="text"
                  />
                  {errors.name ? (
                    <Text fontSize="sm" color="red.500">
                      This field is required
                    </Text>
                  ) : null}
                </FormControl>
              </Stack>
              <Flex justifyContent="flex-end" width="400px" gap="4">
                <Button variant="outline" onClick={onClose}>
                  Cancel
                </Button>
                <Button colorScheme="purple" type="submit" isLoading={isLoading}>
                  Let's Go
                </Button>
              </Flex>
            </Flex>
          </ModalBody>

          <ModalFooter></ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};
const Sidebar: React.FC = () => {
  const navigate = useNavigate();
  const { data, isLoading, isError, error, refetch } = useQuery(projectsQuery());
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { pathname } = useLocation();
  const { mutateAsync, isLoading: isSaving } = useMutation(createProject);

  const onCreateProject = async ({ name }: Inputs) => {
    try {
      const project = await mutateAsync(makeProject(name));
      if (project?.id) {
        navigate(`/projects/${project.id}/`);
        refetch();
        onClose();
      }
    } catch (error: any) {
      console.log('::Error Creating Project::');
      console.error(error);
    }
  };

  return (
    <Flex
      as="aside"
      height="100%"
      overflowX="auto"
      direction="column"
      justifyContent="space-between"
      padding="2rem 1rem"
      borderRight="2px"
      borderRightColor="gray.200"
    >
      <List spacing={2} width="100%">
        {isLoading && <span>Loading...</span>}
        {isError && <span>Error: {(error as any)?.message}</span>}
        <ListItem fontWeight="700">
          <Heading as="h2" fontSize="1.5rem">
            Projects
          </Heading>
        </ListItem>
        <Divider />
        {data
          ? data?.map((project) => (
              <React.Fragment key={project.id}>
                <ListItem color={pathname.includes(project.id) ? 'purple.500' : 'black'}>
                  <Flex flexDirection="row" alignItems="center">
                    {pathname.includes(project.id) ? (
                      <ListIcon color="purple.500" fontSize="0.6rem">
                        <FontAwesomeIcon icon={['fas', 'square']} />
                      </ListIcon>
                    ) : null}
                    <NavLink to={`/projects/${project.id}`}>{project.name}</NavLink>
                  </Flex>
                </ListItem>
                <Divider />
              </React.Fragment>
            ))
          : null}
        <ListItem
          onClick={onOpen}
          cursor="pointer"
          _hover={{ transform: 'scale(1.04)', transition: 'all 0.2s ease-in-out' }}
        >
          + Create new project
        </ListItem>
        <Divider />
        {isOpen ? (
          <CreateNewProjectModal
            isOpen={isOpen}
            onClose={onClose}
            onCreateProject={onCreateProject}
            isLoading={isSaving}
          />
        ) : null}
      </List>

      <Divider />

      <List spacing={2}>
        <ListItem>
          <Button colorScheme="purple" variant="outline" size="xs" onClick={() => authService.logout()}>
            Sign out
          </Button>
        </ListItem>
      </List>
    </Flex>
  );
};

export default Sidebar;
