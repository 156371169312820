import React from 'react';
import { Flex, Box, Stack, Heading } from '@chakra-ui/react';

export const PollClosedView: React.FC = () => {
  return (
    <Flex height="100vh" width="100vw" flexDirection="column">
      <Flex padding={10} width="100%" flexDirection="column" alignItems="center">
        <Box w="md" p={10} pt={120} bg="white" borderRadius="md">
          <Stack spacing={4}>
            <Heading as="h1" size="lg" mb={5}>
              Poll is already closed
            </Heading>
          </Stack>
        </Box>
      </Flex>
    </Flex>
  );
};
