import { Flex } from '@chakra-ui/react';
import { createStore, StateMachineProvider } from 'little-state-machine';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Step1 } from './Step1';
import { Step2 } from './Step2';
import { Step3 } from './Step3';

createStore(
  {
    onboarding: {
      experience: 'star',
      organisation: 'student',
      branding: {
        brandingColor: '#6b46c1',
      },
    },
  },
  { name: 'onboarding' }
);

export const Onboarding: React.FC = () => {
  return (
    <StateMachineProvider>
      <Flex width="100%" p={6}>
        <Routes>
          <Route path="" element={<Step1 />} />
          <Route path="step-2" element={<Step2 />} />
          <Route path="step-3" element={<Step3 />} />
        </Routes>
      </Flex>
    </StateMachineProvider>
  );
};
