import {
  faArrowAltLeft,
  faArrowLeft,
  faArrowRight,
  faCameraMovie,
  faChartBar,
  faChartLine,
  faCog,
  faCommentAltSmile,
  faEnvelope,
  faEye,
  faEyeSlash,
  faFilm,
  faLockAlt,
  faPlus,
  faQuestion,
  faShareAlt,
  faSmile,
  faTelescope,
  faTrash,
  faUfo,
  faUser,
} from '@fortawesome/pro-light-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCheckCircle, faPlayCircle, faSquare } from '@fortawesome/free-solid-svg-icons';

library.add(
  faArrowAltLeft,
  faArrowLeft,
  faArrowRight,
  faCameraMovie,
  faChartBar,
  faChartLine,
  faCheckCircle,
  faCog,
  faCommentAltSmile,
  faEnvelope,
  faEye,
  faEyeSlash,
  faFilm,
  faLockAlt,
  faPlayCircle,
  faPlus,
  faQuestion,
  faShareAlt,
  faSmile,
  faSquare,
  faTelescope,
  faTrash,
  faUfo,
  faUser
);
