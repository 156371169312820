import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import '@fontsource/lato/latin.css';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { createRoot } from 'react-dom/client';
import { App } from './App';
import './icons';
import './styles/index.css';
import { queryClient } from './utils';

const theme = extendTheme({
  fonts: {
    body: 'Lato',
    heading: 'Lato',
  },
  styles: {
    global: {
      body: {
        fontFamily: 'Lato',
      },
      heading: {
        fontFamily: 'Lato',
      },
    },
  },
});

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <ChakraProvider resetCSS theme={theme}>
    <QueryClientProvider client={queryClient}>
      <App />
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </ChakraProvider>
);
