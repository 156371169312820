import { AxiosRequestConfig } from 'axios';
import { ApiEndpoints, http } from '../Authentication/api';
import { Poll } from '../types';

// TODO: Fix interfaces
interface Branding {
  [key: string]: string;
}
interface Project {
  [key: string]: any;
}
interface ProjectWithRelation {
  [key: string]: any;
}
const { ProjectsUrl, PollsUrl } = ApiEndpoints;

type ProjectResponse = {
  data: ProjectWithRelation;
};
type ProjectsResponse = {
  data: Project[];
};
type PollResponse = {
  data: Poll;
};
type PollsResponse = {
  data: Poll[];
};

export const fetchProjects = async (): Promise<ProjectsResponse> => {
  return await http.get<Project[], ProjectsResponse>(`${ProjectsUrl}`);
};

export const fetchProject = async (id: string): Promise<ProjectResponse> => {
  return await http.get<Project, ProjectResponse>(`${ProjectsUrl}/${id}`);
};

export const createProject = async (project: Partial<Project>, config?: AxiosRequestConfig): Promise<Project> => {
  const { data } = await http
    .post<Partial<Project>, ProjectResponse>(`${ProjectsUrl}`, project, config)
    .then((response: ProjectResponse) => response);
  return data;
};

type PollCreator = Partial<Poll> & { projectId: string };
export const createProjectPoll = async (
  { name, description, projectId }: PollCreator,
  config?: AxiosRequestConfig
): Promise<Poll> => {
  const { data } = await http
    .post<PollCreator, PollResponse>(`${PollsUrl}`, { name, description, projectId }, config)
    .then((response: PollResponse) => response);

  return data;
};

export const fetchProjectPolls = async (projectId: string): Promise<Poll[]> => {
  const { data } = await http
    .get<Poll[], PollsResponse>(`${ProjectsUrl}/${projectId}/polls`)
    .then((response: PollsResponse) => response);
  return data;
};

export const updateProjectBranding = async ({
  projectId,
  branding,
}: {
  projectId: string;
  branding: Partial<Branding>;
}): Promise<any> => {
  const { data } = await http
    .put(`${ProjectsUrl}/${projectId}/branding`, { logoUrl: branding.logoUrl, colorCode: branding.colorCode })
    .then((response) => response);
  return data;
};
